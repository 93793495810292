::ng-deep {
  .more-chip-tooltip {
    width: max-content !important;
    padding: 0.625rem 1.25rem !important;
  }

  .hover-tooltip {
    width: max-content !important;
    padding: 0.15rem 0.25rem !important;

    .simpleText {
      font-size: 0.625rem !important;
    }
  }
}
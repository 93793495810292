@import './../deleted-profiles/components/delete-logs-list/delete-logs-list.component.scss';
@import './../deleted-profiles/delete-profiles.component.scss';
@import './../profile-listing/profile-listing.component.scss';
@import './../profile-listing/components/profile-adv-search/profile-adv-search.component.scss';
@import './../profile-listing/components/profile-search/profile-search.component.scss';
@import './../profile-listing/components/user-profile/user-profile.component.scss';

.customer-360-ngx-datatable {
    .datatable-row-even {
      @apply bg-primary-1/[0.02];
    }
  }
.nce-attributes-ngx-datatable{
  .datatable-row-even {
    @apply bg-primary-1/[0.02];
  }
  &.ngx-datatable {
    @apply rounded shadow-none;

    .material .resize-handle {
        @apply border-r-0;
    }
    .datatable-body {
        .datatable-scroll .datatable-row-wrapper {
            @apply pl-0 #{!important};
            .datatable-body-row {
                .datatable-row-center {
                    .datatable-body-cell {
                        @apply text-xs #{!important};
                    }
                    .datatable-body-cell-label {
                        @apply mx-0 my-auto;
                        @apply overflow-hidden text-ellipsis text-grey/52 #{!important};
                    }
                }
            }
        }
    }
    .datatable-row-group {
        @apply border-b-[0.06rem] border-b-grey-150 border-solid;
        @apply hover:bg-white hover:duration-0 #{!important};
    }
    .datatable-header-inner {
        .datatable-row-center {
                @apply text-xs #{!important};
                .datatable-header-cell-template-wrap {
                    @apply flex items-center;
                }
            }
        }
    }
}


.profile-attr-ctn{
  .sideOverlay-container {
    @apply w-1/4 flex flex-col #{!important};
    .header-box {
      @apply flex flex-col h-28 justify-center items-start py-2 px-4;
      .input-comp {
          @apply min-h-0;
          .msgs-container {
              @apply min-h-0;
          }
      }
  }}
}

  .calculated-attr-ctn{
    .sideOverlay-container {
      @apply w-1/4 flex flex-col #{!important};
      .header-box {
        @apply flex flex-col h-28 justify-center items-start py-2 px-4;
        .input-comp {
            @apply min-h-0;
            .msgs-container {
                @apply min-h-0;
            }
        }
    }}
  }

//TAILWIND_REFACTORED
.delete-logs-ctn {
    zap-modal-holder .body-section {
        @apply bg-white h-[100%_-_5rem];
    }
}
.deleted-profile-tooltip {
    @apply min-w-[5rem] max-w-max leading-[1.2rem] py-[0.3rem] px-[0.625rem] rounded;
    .simpleText {
        @apply text-[0.7rem] break-words;
    }
}
zap-delete-profiles {
  @apply flex h-full;
}

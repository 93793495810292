
zap-mapping-preview{ 
  .mapping-preview-body {
    .datatable-header {
      @apply border-solid border-b-2 border-b-grey-300 border-t-0 border-x-0;
    }
    .empty-row {
      @apply px-1 py-4;
    }
    .datatable-body-row {
      @apply px-2 py-0;
    }
  }
}

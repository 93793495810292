@import './../components/styles/components.scss';
@import './../containers/styles/containers.scss';

// Hardcoded values need to be changed, to check with UX team
// Sample option for `from-{position}` and `to-{position}`, facing issue during build, one possible solution is tailwind latest @3.3.3
// .Paused {
//   @apply before:bg-grey-400 bg-[radial-gradient(circle_at_50%_50%,var(--tw-gradient-stops))] from-[rgba(165,172,177)]/0 from-0% to-grey-400 to-100%;
// }

.status-with-image {
  .Licensed {
    @apply before:bg-secondary-3-100 bg-[radial-gradient(circle_at_50%_50%,var(--tw-gradient-stops))] from-[rgb(90,237,194)]/0 to-secondary-3-100/[28%];
  }

  .In-Progress {
    @apply before:bg-state-warning-1 bg-[radial-gradient(circle_at_50%_50%,var(--tw-gradient-stops))] from-state-warning-1/0 to-state-warning-1/25;
  }

  .Success {
    @apply before:bg-state-success-700 bg-[radial-gradient(205.45%_205.45%_at_50%_50%,var(--tw-gradient-stops))] from-[rgba(27,150,150)]/0 to-state-success-700;
  }

  .Active {
    @apply before:bg-state-success bg-[radial-gradient(circle_at_50%_50%,var(--tw-gradient-stops))] from-[rgb(90,237,194)]/0 to-state-success/25;
  }

  .InActive {
    @apply before:bg-grey/[52%] bg-[radial-gradient(205.45%_205.45%_at_50%_50%,var(--tw-gradient-stops))] from-[rgba(111,110,110)]/0 to-grey/[52%];
  }

  .Inactive, .Failed {
    @apply before:bg-state-error-700 bg-[radial-gradient(circle_at_50%_50%,var(--tw-gradient-stops))] from-[rgba(235,65,93)]/0 to-state-error-700/25;
  }

  .Paused {
    @apply before:bg-grey-400 bg-[radial-gradient(circle_at_50%_50%,var(--tw-gradient-stops))] from-[rgba(165,172,177)]/0 to-grey-400;
  }
}

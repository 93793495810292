.create-global-props-container {
  //TAILWIND_REFACTORED
  .body-section {
    @apply pt-6 pb-16 px-16 #{!important};
  }
  .form-row {
    @apply grid-cols-[50%_42%_2rem] gap-[1.4375rem] #{!important};
  }
  .control-holder {
    @apply pl-0 #{!important};
  }
  .add-btn {
    @apply pl-0 #{!important};
  }
  .tooltip-style {
    @apply inline-block ml-2;
  }
  .infoIcon {
    @apply h-3 w-3 align-middle text-2xs text-grey/52 ml-[0.7rem] px-1 py-[0.15rem] rounded-[50%] bg-grey-100;
  }
  .tooltip-dynamic-form {
    @apply w-[9.5rem] px-2 py-[0.3125rem] #{!important};
  }
  .tooltip-dynamic-form .simpleText {
    @apply text-xs leading-[initial] inline-block;
  }
}

zap-pixel-source, zap-create-global-props {
  .modal-holder .container {
    @apply w-3/5 h-[70%] min-h-[25rem] bg-white border rounded shadow-7 -translate-x-2/4 -translate-y-2/4 fixed p-0 border-solid border-grey-300 left-2/4 top-2/4;
    border-image-source: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.4) 5%,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0)
    );
  }
  .footer {
    @apply shadow-none #{!important};
    .cancel-button,
    .next-button {
      @apply h-10 text-base font-secondary-bold min-h-[unset] w-max min-w-[unset] #{!important};
    }
  }
}

.create-pixel-modal-holder {
  .container {
    @apply h-[90%] #{!important};
  }

  .form-row {
    @apply grid-cols-[30%_40%_20%_2rem] gap-[1.4375rem] #{!important};
  }

  .toggle {
    @apply mt-2 #{!important};
  }

  .infoIcon {
    @apply text-grey/52 relative border border-solid border-grey-300/30 bg-grey-100;
    @apply p-[0.282rem] #{!important};
  }

  .size-desc {
    @apply text-white absolute leading-[0.875rem] w-[11.75rem] p-2 bg-grey-800;
  }

  .content {
    @apply text-white;
  }

  .tooltip-arrow {
    @apply content-[""] top-[-0.625rem] border-[5px] border-solid border-[transparent_transparent_black_transparent] left-36 transform-none;
  }

  .tooltip-bottom {
    @apply ml-[-8.4375rem] #{!important};
  }

  .icon.down-triangle:before {
    @apply content-[''];
  }

  @media only screen and (max-width: 1024px) {
    .form-row {
      @apply gap-4;
    }

    .sub-heading {
      @apply gap-4 #{!important};
    }
  }

  .url-heading {
    @apply text-grey/52 flex;
  }

  .sub-heading {
    @apply grid grid-cols-[30%_38%_24%_2rem] gap-[1.4375rem];
  }
}

.encoder-container .copy {
  @apply text-sm pl-4 pt-4;
}

.encoder-container .copy .clipboard {
  @apply cursor-pointer #{!important};
}

.encoder-container .copy .clipboard .copyBtn {
  @apply pt-1 pb-[0.12rem] px-1 bg-grey-100;
}

.encoder-container .copy .clipboard:hover .tooltiptext {
  @apply ml-[-2.5rem] #{!important};
}

.encoder-container .hideBase64 {
  @apply invisible;
}

.pixel-event-container .tab-container .tablink {
  @apply text-grey-800 text-sm;
  @apply border border-solid border-[transparent] border-b-0 #{!important};
}
.pixel-event-container .tab-container .active {
  @apply text-primary-1-900 bg-grey-50 border rounded-tl rounded-tr px-[0.47rem] py-[0.45rem] border-b-0 border-solid border-grey-300/30 #{!important};
}
.pixel-event-container .copyBtn {
  @apply text-[#8A94A9] bg-[#D3DCEB] cursor-pointer pt-1 pb-[0.12rem] px-[0.31rem] #{!important}; //TODO: FIX COLOR
}
.pixel-event-container .clipboard:hover .tooltiptext {
  @apply left-4;
}

.disable-edit-pixel-tooltip {
  @apply min-w-[10.5rem] max-w-[18rem];
  @apply px-2 py-[0.3125rem] #{!important};
}
.disable-edit-pixel-tooltip .simpleText {
  @apply text-xs inline-block;
}

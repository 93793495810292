//TAILWIND_REFACTORED
.source-wizard-container {
  .source-wizard-footer .actions-bar {
    @apply relative pl-4 pr-10 py-2.5 #{!important};
  }

  .source-create-doc {
    .help-center {
      @apply -top-1.5;
    }
  }

  .source-action-validation-error {
    .snackBar {
      @apply left-[calc(50vw_-_17rem)] w-max px-4 py-2.5 top-6 #{!important};
    }
  }

  .stage-divider::before {
    @apply rotate-z-270;
  }
}

zap-key-mapping-enricher {
  .error-dropdown-border .ng-select-container {
    @apply border-[0.0625rem] border-solid border-state-error-700 #{!important};
  }
}

.validation-tooltip {
    @apply min-w-[14rem] max-w-[18rem] w-max leading-4 py-[0.3125rem] px-[0.625rem] #{!important};
    .error {
      @apply text-state-error-700;
    }
    .error-text {
      @apply text-xs text-white;
    }
  }
  
@font-face {font-family: "unity-icons";
  src: url('unity-icons.eot?t=1701329737209'); /* IE9*/
  src: url('unity-icons.eot?t=1701329737209#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("unity-icons.woff2?t=1701329737209") format("woff2"),
  url("unity-icons.woff?t=1701329737209") format("woff"),
  url('unity-icons.ttf?t=1701329737209') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('unity-icons.svg?t=1701329737209#unity-icons') format('svg'); /* iOS 4.1- */
}

[class^="unity-icons-"], [class*=" unity-icons-"] {
  font-family: 'unity-icons' !important;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.unity-icons-1p:before { content: "\ea01"; }
.unity-icons-acativate:before { content: "\ea02"; }
.unity-icons-activate:before { content: "\ea03"; }
.unity-icons-add-destination:before { content: "\ea04"; }
.unity-icons-add:before { content: "\ea05"; }
.unity-icons-admin:before { content: "\ea06"; }
.unity-icons-admincdp:before { content: "\ea07"; }
.unity-icons-affinity_360:before { content: "\ea08"; }
.unity-icons-agreement:before { content: "\ea09"; }
.unity-icons-alert-filled:before { content: "\ea0a"; }
.unity-icons-api:before { content: "\ea0b"; }
.unity-icons-app-event:before { content: "\ea0c"; }
.unity-icons-apps:before { content: "\ea0d"; }
.unity-icons-archive-folder:before { content: "\ea0e"; }
.unity-icons-archive:before { content: "\ea0f"; }
.unity-icons-arrow-up-circle:before { content: "\ea10"; }
.unity-icons-arrow:before { content: "\ea11"; }
.unity-icons-art:before { content: "\ea12"; }
.unity-icons-audience:before { content: "\ea13"; }
.unity-icons-authoring-tool:before { content: "\ea14"; }
.unity-icons-automobile:before { content: "\ea15"; }
.unity-icons-automotive:before { content: "\ea16"; }
.unity-icons-automotive_app:before { content: "\ea17"; }
.unity-icons-basic-details:before { content: "\ea18"; }
.unity-icons-beauty:before { content: "\ea19"; }
.unity-icons-bell:before { content: "\ea1a"; }
.unity-icons-blacklist:before { content: "\ea1b"; }
.unity-icons-book_open:before { content: "\ea1c"; }
.unity-icons-books:before { content: "\ea1d"; }
.unity-icons-brand:before { content: "\ea1e"; }
.unity-icons-bucket:before { content: "\ea1f"; }
.unity-icons-burger-option:before { content: "\ea20"; }
.unity-icons-business:before { content: "\ea21"; }
.unity-icons-calc-attribute:before { content: "\ea22"; }
.unity-icons-calc:before { content: "\ea23"; }
.unity-icons-calendar:before { content: "\ea24"; }
.unity-icons-campaign-1:before { content: "\ea25"; }
.unity-icons-cancel:before { content: "\ea26"; }
.unity-icons-cards:before { content: "\ea27"; }
.unity-icons-catalog:before { content: "\ea28"; }
.unity-icons-categories:before { content: "\ea29"; }
.unity-icons-cdplogo:before { content: "\ea2a"; }
.unity-icons-change-in-attr:before { content: "\ea2b"; }
.unity-icons-check:before { content: "\ea2c"; }
.unity-icons-checkbox_not-selcted:before { content: "\ea2d"; }
.unity-icons-checkbox_not-selcted2:before { content: "\ea2e"; }
.unity-icons-cheveron-down:before { content: "\ea2f"; }
.unity-icons-chevoron:before { content: "\ea30"; }
.unity-icons-circle-outline:before { content: "\ea31"; }
.unity-icons-clear:before { content: "\ea32"; }
.unity-icons-clicks:before { content: "\ea33"; }
.unity-icons-clock:before { content: "\ea34"; }
.unity-icons-close:before { content: "\ea35"; }
.unity-icons-columns:before { content: "\ea36"; }
.unity-icons-comics:before { content: "\ea37"; }
.unity-icons-communication:before { content: "\ea38"; }
.unity-icons-connect-icon:before { content: "\ea39"; }
.unity-icons-consent-purpose-2:before { content: "\ea3a"; }
.unity-icons-conversions:before { content: "\ea3b"; }
.unity-icons-copy:before { content: "\ea3c"; }
.unity-icons-crowd:before { content: "\ea3d"; }
.unity-icons-csv:before { content: "\ea3e"; }
.unity-icons-dash:before { content: "\ea3f"; }
.unity-icons-data:before { content: "\ea40"; }
.unity-icons-database:before { content: "\ea41"; }
.unity-icons-datamanager:before { content: "\ea42"; }
.unity-icons-dating:before { content: "\ea43"; }
.unity-icons-dcr:before { content: "\ea44"; }
.unity-icons-delete-segment:before { content: "\ea45"; }
.unity-icons-delete:before { content: "\ea46"; }
.unity-icons-demographics-new:before { content: "\ea47"; }
.unity-icons-demographics:before { content: "\ea48"; }
.unity-icons-destinations:before { content: "\ea49"; }
.unity-icons-device-and-contract:before { content: "\ea4a"; }
.unity-icons-discover:before { content: "\ea4b"; }
.unity-icons-distribute-icon:before { content: "\ea4c"; }
.unity-icons-distribute:before { content: "\ea4d"; }
.unity-icons-doc-file:before { content: "\ea4e"; }
.unity-icons-doc-with-person:before { content: "\ea4f"; }
.unity-icons-dot:before { content: "\ea50"; }
.unity-icons-download-file:before { content: "\ea51"; }
.unity-icons-download-report:before { content: "\ea52"; }
.unity-icons-download:before { content: "\ea53"; }
.unity-icons-dpui-icon:before { content: "\ea54"; }
.unity-icons-dpui:before { content: "\ea55"; }
.unity-icons-dropdown:before { content: "\ea56"; }
.unity-icons-duplicate:before { content: "\ea57"; }
.unity-icons-edit-pencil:before { content: "\ea58"; }
.unity-icons-edit:before { content: "\ea59"; }
.unity-icons-education:before { content: "\ea5a"; }
.unity-icons-ellipse:before { content: "\ea5b"; }
.unity-icons-enricher:before { content: "\ea5c"; }
.unity-icons-entertainment:before { content: "\ea5d"; }
.unity-icons-envision:before { content: "\ea5e"; }
.unity-icons-euro:before { content: "\ea5f"; }
.unity-icons-events-3:before { content: "\ea60"; }
.unity-icons-events:before { content: "\ea61"; }
.unity-icons-export:before { content: "\ea62"; }
.unity-icons-extensions:before { content: "\ea63"; }
.unity-icons-failed:before { content: "\ea64"; }
.unity-icons-female:before { content: "\ea65"; }
.unity-icons-files:before { content: "\ea66"; }
.unity-icons-filled-tools:before { content: "\ea67"; }
.unity-icons-filter-icon:before { content: "\ea68"; }
.unity-icons-filter:before { content: "\ea69"; }
.unity-icons-filter1:before { content: "\ea6a"; }
.unity-icons-filter2:before { content: "\ea6b"; }
.unity-icons-finance:before { content: "\ea6c"; }
.unity-icons-flag:before { content: "\ea6d"; }
.unity-icons-folder-filled:before { content: "\ea6e"; }
.unity-icons-folder:before { content: "\ea6f"; }
.unity-icons-food-and-drink:before { content: "\ea70"; }
.unity-icons-games:before { content: "\ea71"; }
.unity-icons-globe:before { content: "\ea72"; }
.unity-icons-graph:before { content: "\ea73"; }
.unity-icons-have-entry-event-attr:before { content: "\ea74"; }
.unity-icons-health-n-fitness:before { content: "\ea75"; }
.unity-icons-help:before { content: "\ea76"; }
.unity-icons-hold:before { content: "\ea77"; }
.unity-icons-home:before { content: "\ea78"; }
.unity-icons-http:before { content: "\ea79"; }
.unity-icons-id-5:before { content: "\ea7a"; }
.unity-icons-id-meta-4:before { content: "\ea7b"; }
.unity-icons-id:before { content: "\ea7c"; }
.unity-icons-idcard:before { content: "\ea7d"; }
.unity-icons-iframe:before { content: "\ea7e"; }
.unity-icons-impressions:before { content: "\ea7f"; }
.unity-icons-info-2:before { content: "\ea80"; }
.unity-icons-info-tip:before { content: "\ea81"; }
.unity-icons-info:before { content: "\ea82"; }
.unity-icons-info_new:before { content: "\ea83"; }
.unity-icons-ingested:before { content: "\ea84"; }
.unity-icons-ingestion-filled:before { content: "\ea85"; }
.unity-icons-ingestion:before { content: "\ea86"; }
.unity-icons-inprogress:before { content: "\ea87"; }
.unity-icons-insights:before { content: "\ea88"; }
.unity-icons-integrate:before { content: "\ea89"; }
.unity-icons-intent-icon:before { content: "\ea8a"; }
.unity-icons-intent:before { content: "\ea8b"; }
.unity-icons-interests:before { content: "\ea8c"; }
.unity-icons-javascript:before { content: "\ea8d"; }
.unity-icons-libraries:before { content: "\ea8e"; }
.unity-icons-license-to:before { content: "\ea8f"; }
.unity-icons-license-to2:before { content: "\ea90"; }
.unity-icons-lifestyle:before { content: "\ea91"; }
.unity-icons-list:before { content: "\ea92"; }
.unity-icons-lock:before { content: "\ea93"; }
.unity-icons-logout:before { content: "\ea94"; }
.unity-icons-lookalike1:before { content: "\ea95"; }
.unity-icons-male:before { content: "\ea96"; }
.unity-icons-map:before { content: "\ea97"; }
.unity-icons-mapp:before { content: "\ea98"; }
.unity-icons-maps-n-navigation:before { content: "\ea99"; }
.unity-icons-marketing-6:before { content: "\ea9a"; }
.unity-icons-matched-icon:before { content: "\ea9b"; }
.unity-icons-matchrate:before { content: "\ea9c"; }
.unity-icons-medical:before { content: "\ea9d"; }
.unity-icons-message_square:before { content: "\ea9e"; }
.unity-icons-moving-winddow:before { content: "\ea9f"; }
.unity-icons-music:before { content: "\eaa0"; }
.unity-icons-mweb:before { content: "\eaa1"; }
.unity-icons-news-n-magazines:before { content: "\eaa2"; }
.unity-icons-options:before { content: "\eaa3"; }
.unity-icons-other-details:before { content: "\eaa4"; }
.unity-icons-parenting:before { content: "\eaa5"; }
.unity-icons-pause_icon:before { content: "\eaa6"; }
.unity-icons-people:before { content: "\eaa7"; }
.unity-icons-personalization:before { content: "\eaa8"; }
.unity-icons-phone:before { content: "\eaa9"; }
.unity-icons-photography:before { content: "\eaaa"; }
.unity-icons-pixel:before { content: "\eaab"; }
.unity-icons-pixels:before { content: "\eaac"; }
.unity-icons-policy-editor:before { content: "\eaad"; }
.unity-icons-pound:before { content: "\eaae"; }
.unity-icons-preview:before { content: "\eaaf"; }
.unity-icons-product-7:before { content: "\eab0"; }
.unity-icons-productivity:before { content: "\eab1"; }
.unity-icons-profile:before { content: "\eab2"; }
.unity-icons-psychographics:before { content: "\eab3"; }
.unity-icons-purchase:before { content: "\eab4"; }
.unity-icons-rbac:before { content: "\eab5"; }
.unity-icons-re-entry:before { content: "\eab6"; }
.unity-icons-received:before { content: "\eab7"; }
.unity-icons-records-matched:before { content: "\eab8"; }
.unity-icons-records-matched1:before { content: "\eab9"; }
.unity-icons-records-matched2:before { content: "\eaba"; }
.unity-icons-references:before { content: "\eabb"; }
.unity-icons-reload:before { content: "\eabc"; }
.unity-icons-restore:before { content: "\eabd"; }
.unity-icons-resume:before { content: "\eabe"; }
.unity-icons-revenue:before { content: "\eabf"; }
.unity-icons-rupee:before { content: "\eac0"; }
.unity-icons-sampling:before { content: "\eac1"; }
.unity-icons-scissors:before { content: "\eac2"; }
.unity-icons-search:before { content: "\eac3"; }
.unity-icons-segment-cdp:before { content: "\eac4"; }
.unity-icons-segment-lifecycle-1:before { content: "\eac5"; }
.unity-icons-segments-1:before { content: "\eac6"; }
.unity-icons-segments-2:before { content: "\eac7"; }
.unity-icons-segments:before { content: "\eac8"; }
.unity-icons-send-mail:before { content: "\eac9"; }
.unity-icons-send:before { content: "\eaca"; }
.unity-icons-server2server:before { content: "\eacb"; }
.unity-icons-settings:before { content: "\eacc"; }
.unity-icons-shopping:before { content: "\eacd"; }
.unity-icons-social:before { content: "\eace"; }
.unity-icons-sort:before { content: "\eacf"; }
.unity-icons-split:before { content: "\ead0"; }
.unity-icons-sports:before { content: "\ead1"; }
.unity-icons-striped-edit:before { content: "\ead2"; }
.unity-icons-success:before { content: "\ead3"; }
.unity-icons-switcheroo:before { content: "\ead4"; }
.unity-icons-symphony-old:before { content: "\ead5"; }
.unity-icons-symphony:before { content: "\ead6"; }
.unity-icons-table-placeholder:before { content: "\ead7"; }
.unity-icons-tag-mangers:before { content: "\ead8"; }
.unity-icons-target:before { content: "\ead9"; }
.unity-icons-targeting:before { content: "\eada"; }
.unity-icons-time:before { content: "\eadb"; }
.unity-icons-time1:before { content: "\eadc"; }
.unity-icons-tools:before { content: "\eadd"; }
.unity-icons-travel-n-local:before { content: "\eade"; }
.unity-icons-travel:before { content: "\eadf"; }
.unity-icons-unie905:before { content: "\eae0"; }
.unity-icons-unie906:before { content: "\eae1"; }
.unity-icons-unie9d3:before { content: "\eae2"; }
.unity-icons-unie9d4:before { content: "\eae3"; }
.unity-icons-unify:before { content: "\eae4"; }
.unity-icons-update-properties:before { content: "\eae5"; }
.unity-icons-uploaded:before { content: "\eae6"; }
.unity-icons-user-management:before { content: "\eae7"; }
.unity-icons-user-management2:before { content: "\eae8"; }
.unity-icons-user-trait-8:before { content: "\eae9"; }
.unity-icons-user-x:before { content: "\eaea"; }
.unity-icons-user360:before { content: "\eaeb"; }
.unity-icons-utilitiees:before { content: "\eaec"; }
.unity-icons-vertex_ai:before { content: "\eaed"; }
.unity-icons-video:before { content: "\eaee"; }
.unity-icons-view-off:before { content: "\eaef"; }
.unity-icons-view:before { content: "\eaf0"; }
.unity-icons-warning:before { content: "\eaf1"; }
.unity-icons-weather:before { content: "\eaf2"; }
.unity-icons-website-events:before { content: "\eaf3"; }
.unity-icons-workflow-new-2:before { content: "\eaf4"; }
.unity-icons-workflow:before { content: "\eaf5"; }
.unity-icons-zap:before { content: "\eaf6"; }

$unity-icons-1p: "\ea01";
$unity-icons-acativate: "\ea02";
$unity-icons-activate: "\ea03";
$unity-icons-add-destination: "\ea04";
$unity-icons-add: "\ea05";
$unity-icons-admin: "\ea06";
$unity-icons-admincdp: "\ea07";
$unity-icons-affinity_360: "\ea08";
$unity-icons-agreement: "\ea09";
$unity-icons-alert-filled: "\ea0a";
$unity-icons-api: "\ea0b";
$unity-icons-app-event: "\ea0c";
$unity-icons-apps: "\ea0d";
$unity-icons-archive-folder: "\ea0e";
$unity-icons-archive: "\ea0f";
$unity-icons-arrow-up-circle: "\ea10";
$unity-icons-arrow: "\ea11";
$unity-icons-art: "\ea12";
$unity-icons-audience: "\ea13";
$unity-icons-authoring-tool: "\ea14";
$unity-icons-automobile: "\ea15";
$unity-icons-automotive: "\ea16";
$unity-icons-automotive_app: "\ea17";
$unity-icons-basic-details: "\ea18";
$unity-icons-beauty: "\ea19";
$unity-icons-bell: "\ea1a";
$unity-icons-blacklist: "\ea1b";
$unity-icons-book_open: "\ea1c";
$unity-icons-books: "\ea1d";
$unity-icons-brand: "\ea1e";
$unity-icons-bucket: "\ea1f";
$unity-icons-burger-option: "\ea20";
$unity-icons-business: "\ea21";
$unity-icons-calc-attribute: "\ea22";
$unity-icons-calc: "\ea23";
$unity-icons-calendar: "\ea24";
$unity-icons-campaign-1: "\ea25";
$unity-icons-cancel: "\ea26";
$unity-icons-cards: "\ea27";
$unity-icons-catalog: "\ea28";
$unity-icons-categories: "\ea29";
$unity-icons-cdplogo: "\ea2a";
$unity-icons-change-in-attr: "\ea2b";
$unity-icons-check: "\ea2c";
$unity-icons-checkbox_not-selcted: "\ea2d";
$unity-icons-checkbox_not-selcted2: "\ea2e";
$unity-icons-cheveron-down: "\ea2f";
$unity-icons-chevoron: "\ea30";
$unity-icons-circle-outline: "\ea31";
$unity-icons-clear: "\ea32";
$unity-icons-clicks: "\ea33";
$unity-icons-clock: "\ea34";
$unity-icons-close: "\ea35";
$unity-icons-columns: "\ea36";
$unity-icons-comics: "\ea37";
$unity-icons-communication: "\ea38";
$unity-icons-connect-icon: "\ea39";
$unity-icons-consent-purpose-2: "\ea3a";
$unity-icons-conversions: "\ea3b";
$unity-icons-copy: "\ea3c";
$unity-icons-crowd: "\ea3d";
$unity-icons-csv: "\ea3e";
$unity-icons-dash: "\ea3f";
$unity-icons-data: "\ea40";
$unity-icons-database: "\ea41";
$unity-icons-datamanager: "\ea42";
$unity-icons-dating: "\ea43";
$unity-icons-dcr: "\ea44";
$unity-icons-delete-segment: "\ea45";
$unity-icons-delete: "\ea46";
$unity-icons-demographics-new: "\ea47";
$unity-icons-demographics: "\ea48";
$unity-icons-destinations: "\ea49";
$unity-icons-device-and-contract: "\ea4a";
$unity-icons-discover: "\ea4b";
$unity-icons-distribute-icon: "\ea4c";
$unity-icons-distribute: "\ea4d";
$unity-icons-doc-file: "\ea4e";
$unity-icons-doc-with-person: "\ea4f";
$unity-icons-dot: "\ea50";
$unity-icons-download-file: "\ea51";
$unity-icons-download-report: "\ea52";
$unity-icons-download: "\ea53";
$unity-icons-dpui-icon: "\ea54";
$unity-icons-dpui: "\ea55";
$unity-icons-dropdown: "\ea56";
$unity-icons-duplicate: "\ea57";
$unity-icons-edit-pencil: "\ea58";
$unity-icons-edit: "\ea59";
$unity-icons-education: "\ea5a";
$unity-icons-ellipse: "\ea5b";
$unity-icons-enricher: "\ea5c";
$unity-icons-entertainment: "\ea5d";
$unity-icons-envision: "\ea5e";
$unity-icons-euro: "\ea5f";
$unity-icons-events-3: "\ea60";
$unity-icons-events: "\ea61";
$unity-icons-export: "\ea62";
$unity-icons-extensions: "\ea63";
$unity-icons-failed: "\ea64";
$unity-icons-female: "\ea65";
$unity-icons-files: "\ea66";
$unity-icons-filled-tools: "\ea67";
$unity-icons-filter-icon: "\ea68";
$unity-icons-filter: "\ea69";
$unity-icons-filter1: "\ea6a";
$unity-icons-filter2: "\ea6b";
$unity-icons-finance: "\ea6c";
$unity-icons-flag: "\ea6d";
$unity-icons-folder-filled: "\ea6e";
$unity-icons-folder: "\ea6f";
$unity-icons-food-and-drink: "\ea70";
$unity-icons-games: "\ea71";
$unity-icons-globe: "\ea72";
$unity-icons-graph: "\ea73";
$unity-icons-have-entry-event-attr: "\ea74";
$unity-icons-health-n-fitness: "\ea75";
$unity-icons-help: "\ea76";
$unity-icons-hold: "\ea77";
$unity-icons-home: "\ea78";
$unity-icons-http: "\ea79";
$unity-icons-id-5: "\ea7a";
$unity-icons-id-meta-4: "\ea7b";
$unity-icons-id: "\ea7c";
$unity-icons-idcard: "\ea7d";
$unity-icons-iframe: "\ea7e";
$unity-icons-impressions: "\ea7f";
$unity-icons-info-2: "\ea80";
$unity-icons-info-tip: "\ea81";
$unity-icons-info: "\ea82";
$unity-icons-info_new: "\ea83";
$unity-icons-ingested: "\ea84";
$unity-icons-ingestion-filled: "\ea85";
$unity-icons-ingestion: "\ea86";
$unity-icons-inprogress: "\ea87";
$unity-icons-insights: "\ea88";
$unity-icons-integrate: "\ea89";
$unity-icons-intent-icon: "\ea8a";
$unity-icons-intent: "\ea8b";
$unity-icons-interests: "\ea8c";
$unity-icons-javascript: "\ea8d";
$unity-icons-libraries: "\ea8e";
$unity-icons-license-to: "\ea8f";
$unity-icons-license-to2: "\ea90";
$unity-icons-lifestyle: "\ea91";
$unity-icons-list: "\ea92";
$unity-icons-lock: "\ea93";
$unity-icons-logout: "\ea94";
$unity-icons-lookalike1: "\ea95";
$unity-icons-male: "\ea96";
$unity-icons-map: "\ea97";
$unity-icons-mapp: "\ea98";
$unity-icons-maps-n-navigation: "\ea99";
$unity-icons-marketing-6: "\ea9a";
$unity-icons-matched-icon: "\ea9b";
$unity-icons-matchrate: "\ea9c";
$unity-icons-medical: "\ea9d";
$unity-icons-message_square: "\ea9e";
$unity-icons-moving-winddow: "\ea9f";
$unity-icons-music: "\eaa0";
$unity-icons-mweb: "\eaa1";
$unity-icons-news-n-magazines: "\eaa2";
$unity-icons-options: "\eaa3";
$unity-icons-other-details: "\eaa4";
$unity-icons-parenting: "\eaa5";
$unity-icons-pause_icon: "\eaa6";
$unity-icons-people: "\eaa7";
$unity-icons-personalization: "\eaa8";
$unity-icons-phone: "\eaa9";
$unity-icons-photography: "\eaaa";
$unity-icons-pixel: "\eaab";
$unity-icons-pixels: "\eaac";
$unity-icons-policy-editor: "\eaad";
$unity-icons-pound: "\eaae";
$unity-icons-preview: "\eaaf";
$unity-icons-product-7: "\eab0";
$unity-icons-productivity: "\eab1";
$unity-icons-profile: "\eab2";
$unity-icons-psychographics: "\eab3";
$unity-icons-purchase: "\eab4";
$unity-icons-rbac: "\eab5";
$unity-icons-re-entry: "\eab6";
$unity-icons-received: "\eab7";
$unity-icons-records-matched: "\eab8";
$unity-icons-records-matched1: "\eab9";
$unity-icons-records-matched2: "\eaba";
$unity-icons-references: "\eabb";
$unity-icons-reload: "\eabc";
$unity-icons-restore: "\eabd";
$unity-icons-resume: "\eabe";
$unity-icons-revenue: "\eabf";
$unity-icons-rupee: "\eac0";
$unity-icons-sampling: "\eac1";
$unity-icons-scissors: "\eac2";
$unity-icons-search: "\eac3";
$unity-icons-segment-cdp: "\eac4";
$unity-icons-segment-lifecycle-1: "\eac5";
$unity-icons-segments-1: "\eac6";
$unity-icons-segments-2: "\eac7";
$unity-icons-segments: "\eac8";
$unity-icons-send-mail: "\eac9";
$unity-icons-send: "\eaca";
$unity-icons-server2server: "\eacb";
$unity-icons-settings: "\eacc";
$unity-icons-shopping: "\eacd";
$unity-icons-social: "\eace";
$unity-icons-sort: "\eacf";
$unity-icons-split: "\ead0";
$unity-icons-sports: "\ead1";
$unity-icons-striped-edit: "\ead2";
$unity-icons-success: "\ead3";
$unity-icons-switcheroo: "\ead4";
$unity-icons-symphony-old: "\ead5";
$unity-icons-symphony: "\ead6";
$unity-icons-table-placeholder: "\ead7";
$unity-icons-tag-mangers: "\ead8";
$unity-icons-target: "\ead9";
$unity-icons-targeting: "\eada";
$unity-icons-time: "\eadb";
$unity-icons-time1: "\eadc";
$unity-icons-tools: "\eadd";
$unity-icons-travel-n-local: "\eade";
$unity-icons-travel: "\eadf";
$unity-icons-unie905: "\eae0";
$unity-icons-unie906: "\eae1";
$unity-icons-unie9d3: "\eae2";
$unity-icons-unie9d4: "\eae3";
$unity-icons-unify: "\eae4";
$unity-icons-update-properties: "\eae5";
$unity-icons-uploaded: "\eae6";
$unity-icons-user-management: "\eae7";
$unity-icons-user-management2: "\eae8";
$unity-icons-user-trait-8: "\eae9";
$unity-icons-user-x: "\eaea";
$unity-icons-user360: "\eaeb";
$unity-icons-utilitiees: "\eaec";
$unity-icons-vertex_ai: "\eaed";
$unity-icons-video: "\eaee";
$unity-icons-view-off: "\eaef";
$unity-icons-view: "\eaf0";
$unity-icons-warning: "\eaf1";
$unity-icons-weather: "\eaf2";
$unity-icons-website-events: "\eaf3";
$unity-icons-workflow-new-2: "\eaf4";
$unity-icons-workflow: "\eaf5";
$unity-icons-zap: "\eaf6";


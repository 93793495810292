//TAILWIND_REFACTORED
zap-value-mapper-enricher {
    .form-row {
        @apply grid-cols-[40%_2rem_40%_2rem] #{!important};
        @apply gap-2 items-center;
    }
     .control-holder {
        @apply pl-0 #{!important};
    }
    .add-btn {
        @apply pl-0 #{!important};
    }
    .remove-holder {
        @apply pt-0 #{!important};
    }
    ::placeholder {
        @apply text-grey/52 #{!important};
    }
    .form-holder {
        @apply mb-4;
    }
    .form-holder .delete {
        @apply cursor-pointer w-5 h-5 text-xl self-center text-state-error-700 rounded bg-state-error-700/10;
    }
    .ng-select {
        @apply rounded;
    }
    .ng-select .ng-select-container {
        @apply h-10 #{!important};
    }
    .ng-select .ng-select-container .ng-value-container .ng-input>input {
        @apply pt-1;
    }
    .ng-select .ng-select-container .ng-clear-wrapper {
        @apply hidden #{!important};
    }
    .ng-select .ng-select-container .ng-placeholder {
        @apply text-grey/52 #{!important};
    }
}
//TAILWIND_REFACTORED
.delete-logs-table-ctn {
    .ngx-datatable {
        @apply shadow-1 rounded-t rounded-b-none #{!important};
        .datatable-row-group {
            @apply border-solid border-b-[0.06rem] border-grey-150 #{!important};
            &:hover {
                @apply bg-white duration-0 #{!important};
            }
        }
        .datatable-body-row {
            @apply cursor-pointer;
        }
        .resize-handle {
            @apply border-r-0 #{!important};
        }
    }
    .column-header-text {
        @apply text-sm text-grey-600;
    }

    .stage-filter-icon {
        @apply cursor-pointer pl-4 text-primary-1-300/68;
    }
    .descendingOrder {
        &:before {
            @apply rotate-x-180;
        }
    }

    .delete-status-row {
        .status-icon {
            @apply w-[5px] h-[5px] rounded-full mr-2;
        }
        .In-Progress {
            @apply bg-state-warning-1 #{!important};
        }
        .Success {
            @apply bg-primary-1 #{!important};
        }
        .Failed {
            @apply bg-state-error #{!important};
        }
    }
    .status-filter-ctn {
        .filterIconCell{
            @apply -mt-1 p-1 #{!important};
        }
    }

    .datatable-body-cell-label, .zui-content.table-cell-value{
        @apply whitespace-nowrap text-ellipsis overflow-hidden;
    }

    datatable-scroller{
        @apply w-full #{!important};
    }
}

.filter-icon-ctn, .filterIconCell {
        .status-icon {
            @apply text-[5px] #{!important};
        }
        .in-progress-status-color {
            @apply text-state-warning-1 #{!important};
        }
        .success-status-color {
            @apply text-primary-1 #{!important};
        }
        .failed-status-color {
            @apply text-state-error #{!important};
        }
}

.consent-listing-tooltip {
    @apply min-w-[3rem] max-w-max leading-4 py-[0.2rem] px-[0.525rem] rounded w-auto #{!important};
    .simpleText {
        @apply text-[0.6rem] break-words #{!important};
    }
}

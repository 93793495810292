.standard-input {
    @apply bg-white border border-grey-300 rounded text-grey-900 h-10  p-2 w-full;

    &:focus {
        @apply border-primary-1 outline-none;
    }
    &::placeholder,
    &::-webkit-input-placeholder {
        @apply text-grey-900/52 italic;
    }
    &:disabled {
        @apply bg-grey-100 #{!important};
    }
}
.standard-input {
    box-sizing: border-box;
}

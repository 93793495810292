@import './../source-listing/styles/source-listing.component.scss';
@import './../source-analytics-report/styles/source-analytics-report.component.scss';
@import './../source-wizard/source-wizard.component.scss';
@import './../id-strategy/styles/styles.scss';
@import './../id-strategy/priorities-v2/styles/styles.scss';
@import './../source-wizard/components/data-source-list-item/data-source-list-item.component.scss';
@import './../source-details/source-details.component.scss';
@import './../source-details/containers/pixel-source/styles/pixel-source.scss';
@import './../catalogue-mapping/source-catalog-mapping/source-catalog-mapping.component.scss';
@import './../catalog/calculated-attribute-create/calculated-attribute-create.component.scss';
@import './../catalogue-mapping/enricher/enricher-list.component.scss';
@import './../catalogue-mapping/data-group-create/data-group-create.component.scss';
@import './../catalogue-mapping/data-group-create/components/dg-mapper/dg-mapper.component.scss';
@import './../catalogue-mapping/data-group-create/components/consent-mapper/consent-mapper.component.scss';
@import './../catalogue-mapping/enricher/components/derived-value-enricher/derived-value-enricher.component.scss';
@import './../catalogue-mapping/enricher/components/enricher/enricher.component.scss';
@import './../catalogue-mapping/enricher/components/hardcoded-enricher/hardcoded-enricher.component.scss';
@import './../catalogue-mapping/enricher/components/key-mapping-enricher/key-mapping-enricher.component.scss';
@import './../catalogue-mapping/enricher/components/custom-query-builder/custom-query-builder.component.scss';
@import './../catalogue-mapping/enricher/components/value-mapper-enricher/value-mapper-enricher.component.scss';
@import './../catalog/catalogue-field-create-legacy/add-catalogue-field/add-catalogue-field.component.scss';
@import './../catalogue-mapping//data-group-create/components/mapping-preview/mapping-preview.component.scss';

.summary-chart-tooltip {
  @apply shadow-3 #{!important};
  @apply w-[25rem] #{!important};
  @apply bg-white #{!important};
  @apply pl-0 pr-2.5 pt-[0.5625rem] pb-2.5 #{!important};
}

.summary-chart {
  .chart-container {
    .chart {
      width: 35% !important;
    }
    .efficiency {
      display: flex;
      line-height: 1rem;
      flex-direction: column;
      margin-top: 0.875rem;
      .title {
        font-size: 0.625rem;
        @apply text-grey/52;
      }
      .value {
        font-size: 0.9375rem;
        color: #21beab;
      }
    }
  }
}

.status-info-tooltip {
  width: 14.4375rem !important;
  padding: 0.75rem 1rem 0.75rem 0.8125rem !important;
}
.alert-tooltip-text {
  width: 12.875rem !important;
  line-height: 0.9rem !important;
  padding: 0.6875rem 0.8125rem !important;
  border-radius: 0.25rem !important;
  .simpleText {
    font-size: 0.75rem !important;
  }
}
.file-ingestion-table-tooltip {
  @apply w-max py-1.5 px-2.5 whitespace-nowrap #{!important};
  .simpleText {
    @apply text-xs #{!important};
  }
}

.source-actions {
  width: 12rem !important;
  max-width: 12rem !important;
  .action {
    font-family: BlenderPro;
    @apply text-grey-800;
    text-transform: uppercase;
  }
}

.source-list-table-holder {
  .wrap-loader {
    position: fixed !important;
    left: 0;
    top: 0;
  }
  .option-header,
  .option-cell {
    flex-grow: 1;
  }
  .data-source-header,
  .data-source-cell {
    flex-shrink: 2;
  }
  .dataset-id-cell {
    font-size: 0.6875rem !important;
    margin-top: 0.25rem;
  }
  .records-ingested-header,
  .records-ingested-cell {
    .right-indent {
      @media screen and (max-width: 1024px) {
        text-align: left;
      }
    }
  }
}

//reqd to apply status indicators on custom-filter ui-component
.custom-filter-action {
  .ingested,
  .failed,
  .received,
  .inProgress,
  .default {
    &::before {
      content: '';
      display: inline-block;
      height: 0.5rem;
      width: 0.5rem;
      margin-right: 0.75rem;
      border-radius: 50%;
      left: 0.25rem;
      top: 0.25rem;
    }
  }
}

.custom-filter-action .received::before {
  @apply bg-primary-1;
}
.custom-filter-action .ingested::before {
  @apply bg-state-success-500;
}
.custom-filter-action .default::before {
  @apply bg-[#ADB1BA]; //TODO: FIX
}
.custom-filter-action .failed::before {
  @apply bg-state-error-700;
}
.custom-filter-action .inProgress::before {
  @apply bg-state-warning-2-500;
}

.file-ingestion-table .file-name-cell .datatable-body-cell-label {
  @apply text-grey/68 font-primary-medium;
}

zap-source-analytics-file-ingestion {
  .datatable-body-cell {
    @apply text-xs text-grey/52 #{!important};
    .dataset-id-cell {
      @apply text-grey/68 #{!important};
    }
  }
}
.mapping-table-container .sub-table-header .toggle-button {
  @apply h-4 w-4;
}
.attribute-info-container{
.paused::before, .published::before, .active::before, .failed::before, .inprogress::before {
  @apply content-[''] inline-block h-2 w-2 mr-3 rounded-[50%] left-1 top-1;
}
.active::before {
  @apply bg-state-success;
}
.published::before {
  @apply bg-primary-1;
}
.paused::before {
  @apply bg-grey-400;
}
.failed::before {
  @apply bg-state-error-700;
}
.inprogress::before {
  @apply bg-state-warning-1;
}
}
.add-catalog-legacy {
  .sideOverlay-container {
    width: 55% !important;
  }
}

.lists-container {
    .blacklisted-list {
        .chip {
            @apply bg-grey-400/[0.15] border border-solid border-black/[0.3];
            .drag-dot .dot-circle {
                @apply bg-grey-900 border border-solid border-grey-900;
            }
        }
    }
}
.chip.highlight {
    @apply shadow-[0px_0px_30px_rgba(0,0,0,0.35)] rounded border-2 border-solid;
    @apply border-primary-1 #{!important};
}

//TAILWIND_REFACTORED
zap-enricher-list {
    .enrichers-bg {
        @apply fixed w-full h-full z-[99] bg-primary-1-900/[0.44] left-0 top-0;
        .overlay-modal {
            @apply absolute h-full min-h-full w-2/5 right-0 bg-white;
        }
        .enrichers-container {
            @apply h-full;
        }
        .actions-bar {
            @apply pl-5 pr-10 py-2.5;
        }
        .title-container {
            @apply inline-grid auto-rows-[minmax(2.125rem,auto)] w-full fixed border-b border-solid border-grey-300/30 z-[100] px-5 py-[0.3125rem] bg-white;
        }
        .title-container .title {
            @apply text-base flex flex-col justify-center not-italic font-extrabold uppercase font-secondary-bold;
        }
        .title-container .subTitle {
            @apply flex flex-col justify-center;
        }
        .map-container {
            @apply relative inline-grid grid-cols-[minmax(4.625rem,auto)_2.6825rem_minmax(7.625rem,auto)] gap-[1.3175rem] px-5;
        }
        .map-container .mapped-field {
            @apply flex flex-col justify-center;
        }
        .map-container .mapped-field .mapped-field-title {
            @apply text-grey/52 text-xs;
        }
        .map-container .mapped-field .mapped-field-title .mapped-field-data-type {
            @apply border border-solid border-grey-300/30 box-border font-primary text-2xs leading-3 uppercase px-1 py-px rounded-sm bg-grey-50;
        }
        .map-container .mapped-field .mapped-field-value {
            @apply text-sm text-grey/68 max-w-full overflow-hidden whitespace-nowrap text-ellipsis mt-1.5;
        }
        .map-container .mapped-field .mapped-field-value.target-value {
            @apply border border-solid border-grey-300/30 box-border w-fit leading-[1.0625rem] px-[0.3125rem] py-px rounded-2xl bg-grey-50 text-grey-800;
        }
        .map-container .side-arrow {
            @apply text-grey-300 relative flex items-center pt-3.5 before:content-[""] before:w-[2.6825rem] before:h-0.5 before:absolute after:content-[""] after:h-2 after:w-2 after:absolute after:border-t-grey-300 after:border-r-grey-300 after:rotate-45 after:border-r-2 after:border-t-2 after:border-solid after:right-0;
        }
        .map-container .side-arrow::before {
            @apply bg-grey-300;
        }
        .enrichers {
            @apply relative h-[93vh] overflow-y-auto mt-[0.6875rem] top-[3.25rem];
        }
        .enrichers .enrichment-type {
            @apply mt-[1.625rem] px-5 py-0;
        }
        .enrichers .enrichment-type .enrichment-type-title {
            @apply flex flex-col font-primary;
        }
        .enrichers .enrichment-type .enrichment-type-title .title {
            @apply text-sm leading-[1.05rem] text-primary-1-900;
        }
        .enrichers .enrichment-type .enrichment-type-title .required-field {
            @apply text-state-error-700;
        }
        .enrichers .enrichment-type .enrichment-type-title .subTitle {
            @apply text-grey/52 text-xs leading-[0.9rem] mt-1;
        }
        .enrichers .enrichment-type .enrichment-type-option {
            @apply w-[15.0625rem] mt-2.5;
        }
        .enrichers .enrichersList {
            @apply grid gap-4 mt-[2.5625rem] pt-0 pb-11 px-5;
        }
       
    }
        .overlay {
            @apply z-[100];
        }
}

.map-container .tooltip-text {
    @apply w-fit leading-[1.2rem] rounded px-2.5 py-[0.3rem] #{!important};
}  
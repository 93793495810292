//TAILWIND_REFACTORED
//Adding these for source-analytics-report
:root {
  --primary-1-tw: theme(colors.primary-1.DEFAULT);
  --state-warning-2-500-tw: theme(colors.state-warning-2.500);
  --state-success-500-tw: theme(colors.state-success.500);
  --state-error-700-tw: theme(colors.state-error.700);
}

//TAILWIND_REFACTORED
.data-ingestion-summary .ingestion-summary-report .pie-chart {
  @apply translate-x-[1.875rem] translate-y-[1.875rem] #{!important};
}
.data-ingestion-summary .ingestion-summary-report .chart-container .chart {
  @apply w-2/5;
}
.data-ingestion-summary .ingestion-summary-report .chart-container .chart-info {
  @apply w-full mt-0 pt-[5%] #{!important};
}
@media only screen and (max-width: 768px) {
  .data-ingestion-summary {
    @apply gap-8;
  }
}
@media only screen and (max-width: 986px) {
  .efficiency .title {
    @apply flex-wrap;
  }
}

zap-source-analytics-report-modal {
  .x-axis {
    @apply w-[95%] #{!important};
  }
  .y-axis {
    @apply h-[90%] #{!important};
  }
  //   .tick text {
  //   @apply fill-grey-400 text-xxs #{!important};
  // }
  @media screen and (max-width: 768px) {
    .overlay-modal {
      @apply w-[65%] #{!important};
    }
  }
}

//progress chart styles
zap-source-analytics-file-ingestion {
  .file-status .progress-chart {
    @apply overflow-hidden w-[10.625rem] rounded-[1.6875rem];
  }

  .file-status .progress-chart .chart-item:first-child .item-progress {
    @apply rounded-tl-[1.6875rem] rounded-bl-[1.6875rem];
  }

  .file-status .progress-chart .chart-item:last-child .item-progress {
    @apply rounded-tr-[1.6875rem] rounded-br-[1.6875rem];
  }

  @media screen and (max-width: 768px) {
    .file-name-header,
    .file-name-cell {
      @apply min-w-[95px] #{!important};
    }
    .file-drop-date-header,
    .file-drop-date-cell {
      @apply min-w-[105px] #{!important};
    }
  }
}

zap-ingestion-log {
  .sideOverlay-container {
    @apply w-[30%] #{!important};
  }
  .progress-chart {
    @apply w-[14.5rem] overflow-hidden rounded-[1.6875rem] #{!important};
    .chart-item:first-child .item-progress {
      @apply rounded-tl-[1.6875rem] rounded-bl-[1.6875rem];
    }
    .chart-item:last-child .item-progress {
      @apply rounded-tr-[1.6875rem] rounded-br-[1.6875rem];
    }
  }
}

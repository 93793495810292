/* You can add global styles to this file, and also import other style files */
@config './../tailwind.config.cjs';

@import "tailwindcss/base";

@layer base {
  html {
    line-height: unset;
  }
}

@import "tailwindcss/utilities";

@import "./fonts/unity-icons";
@import "./fonts/icons"; // TODO: Remove usages of this , Adding this as usage is quite well
@import "./styles/presets.scss";

@import "./styles/unity-variables.scss";
@import "./styles/inputs.scss"; // TODO: be carefull not to overide all fonts
@import "./styles/fonts.scss";
@import "../../../projects/collect/src/lib/styles/collect.scss";
@import "../../../projects/usage/src/lib/styles/usage.scss";
@import "../../../projects/audience/src/lib/styles/audience.scss";
@import "../../../projects/ui-components/src/lib/styles/styles.scss";
@import "../../../projects/zui-lib/src/lib/checkbox/checkbox.scss";
@import "../../../projects/zui-lib/src/lib/button/button.scss";
@import "../../../projects/symphony-v2/src/lib/styles/symphony-v2.scss";
@import "../../../projects/query-builder-legacy/src/lib/styles/query-builder.scss";
@import "../../../projects/channels-v2/src/lib/styles/common.scss";
@import "../../../projects/symphony-v2/src/lib/components/step-error-count/step-error-count.component.scss";
@import "../../../projects/core/src/lib/styles/presets.scss";
@import "../../../projects/customer360/src/lib/styles/style.scss";
@import "../../../projects/rbac/src/lib/styles/rbac.scss";
@import "../../../projects/request-access/src/lib/styles/request-access.scss";
@import "../../../projects/connect-v2/src/lib/styles/connect.scss";
@import "../../../projects/channels-v2/src/lib/styles/style.scss";
* {
  @apply font-primary;
}

.shadow-left {
  box-shadow: 1rem 0 3rem var(--tw-shadow-color);
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar,
.no-scroll-bar::-webkit-scrollbar,
.ngx-datatable .datatable-body::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide,
.no-scroll-bar,
.ngx-datatable .datatable-body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

// For disabling org-selector
app-main-content-wrapper {
  .ng-select.ng-select-disabled > .ng-select-container {
    @apply cursor-not-allowed bg-grey-100 #{!important};
  }

  .ng-select.ng-select-disabled .ng-arrow-wrapper {
    @apply hidden #{!important};
  }
}
.no-pointer-events {
  @apply pointer-events-none;
}
.floating-menu {
  @apply bg-white rounded-lg;
  .cdk-menu {
    @apply inline-flex flex-col border border-solid border-grey-200/50 rounded-lg bg-grey-200/50 min-w-[200px] shadow-2xl;
  }
  .cdk-menu-item {
    @apply bg-white text-primary-1-900 text-xs cursor-pointer p-4 flex items-start my-[0.5px] min-h-[48px] hover:bg-grey-250 rounded-none last:rounded-b-lg gap-2;
  }
  &.top-radius-0 {
    .cdk-menu {
      @apply rounded-none rounded-b-lg #{!important};
    }
  }
  &.top-rounded {
    .cdk-menu-item {
      @apply first:rounded-t-lg #{!important};
    }
  }
  // We can add more variants of floating-menu here
}

.text-no-wrap {
  text-wrap: nowrap;
}

.create-new-tag {
  @apply relative bottom-[4.5px] items-start #{!important};
}
.ellipses-text {
  @apply overflow-hidden whitespace-nowrap text-ellipsis;
}

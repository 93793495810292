.parent-org-dropdown {
	.ng-select-container {
		@apply h-12 #{!important};
	}

	&.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
		@apply top-3 #{!important};
	}

	&.ng-select .ng-spinner-loader {
		@apply border-l-primary-1 border-t-primary-1/10 border-b-primary-1/10 border-r-primary-1/10 #{!important};
	}
}

.targeting-country-dropdown {
	.ng-select-container {
		@apply h-10 #{!important};
	}

	&.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
		.ng-placeholder {
			@apply top-2 #{!important};
		}

		.ng-input {
			@apply top-2 #{!important};
		}
	}
}
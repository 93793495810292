//TAILWIND_REFACTORED
.user-profile {
  .matched-criteria {
    .info-div {
      @apply text-grey/52;
    }
    .info-criteria {
      @apply py-0 px-1 text-primary-1-900 font-bold h-6 flex my-0 mx-2 items-center;
      background: #fff2df; // TODO: update as per theme
    }
  }
  .profile-info {
    @apply cursor-pointer grid gap-x-4 h-[7.5rem];
    grid-template-columns: 5rem 1fr 5rem;
  }
  .fixed-column {
    @apply flex justify-center items-center;
  }
}

.profile-listing-tooltip {
  @apply min-w-[5rem] max-w-max leading-[1.2rem] py-[0.3rem] px-[0.625rem] rounded #{!important};
  .simpleText {
    @apply text-[0.7rem] break-words #{!important};
  }
}

//TAILWIND_REFACTORED
zap-source-catalog-mapping {
  zap-action-message-box {
    .info-bg {
      @apply bg-white h-max p-2 grid-cols-[auto] #{!important};
    }
    .chip-content {
      @apply mr-1 mb-1 bg-grey-50 py-1 px-2 rounded-[1.125rem] w-fit border-[0.0625rem] border-solid border-grey-300/30 inline-flex;
      .img-content {
        @apply pl-[0.2rem];
      }
      .chip-header {
        @apply text-grey/52 py-0 px-[0.2rem] #{!important};
      }
    }
    .catalogue-field {
      @apply text-sm text-grey/52;
    }
    .messageIcon {
      @apply h-max #{!important};
    }
  }
  zap-dialog-prompt .informationIcon {
    @apply h-12 w-12 align-middle table-cell rounded-[50%] bg-grey-100 border-[2.18182px] border-solid border-grey-300/30 text-grey/52;
  }
}
zap-source-catalog-mapping zap-action-message-box.success-msg {
  .info-bg {
    @apply bg-state-success-500/10 border border-solid border-state-success h-10 py-0 px-2 #{!important};
    .zui-content {
      @apply text-state-success #{!important};
    }
  }
  .messageIcon {
    @apply font-bold bg-state-success;
    @apply text-xs text-white h-[1.1rem] w-[1.1rem] #{!important};
  }
  .icon.selected::before {
    @apply relative -left-[1px] #{!important};
  }
}

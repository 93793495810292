ngx-tooltip-content.ngx-charts-tooltip-content.type-tooltip {
  padding: 0.375rem;
  box-shadow: 0px 4px 8px rgba(1, 22, 68, 0.17);
  @apply font-primary rounded bg-white;
  .tooltip-caret.position-top {
    @apply border-t-[0.4375rem] border-solid border-t-white;
  }
  .tooltip-caret.position-bottom {
    @apply border-b-[0.4375rem] border-solid border-b-white;
  }
  .tooltip-caret.position-right {
    @apply border-r-[0.4375rem] border-solid border-r-white;
  }
  .tooltip-caret.position-left {
    @apply border-l-[0.4375rem] border-solid border-l-white;
  }
}
.custom-chart {
  .chart-items {
    .item:not(:last-child) {
      padding-right: 0.875rem;
      @apply border-r-[1px] border-solid border-grey-200;
    }
  }
  &.line-chart-container {
    .ngx-charts .line-series.inactive {
      transition: unset !important;
      opacity: unset !important;
    }
    .ngx-charts .line-highlight.active {
      @apply hidden #{!important};
    }
    .ngx-charts rect.tooltip-bar {
      @apply fill-none;
    }
    g.line-chart > g:last-of-type > g:nth-child(n) g.line-series > path {
      stroke-width: 3;
    }
    .single-data-point {
      stroke-width: 10 !important;
      stroke-linecap: round;
    }
  }
  .tick {
    line-height: 1.125rem !important;
    @apply text-grey-400 #{!important};
  }
  .tick text {
    @apply fill-grey-600 text-xxs #{!important};
  }
  .axes-border {
    @apply stroke-grey-600;
  }

  .x-label,
  .y-label {
    line-height: 1.125rem !important;
    @apply text-grey-400 text-2xs #{!important};
  }
}

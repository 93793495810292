
//ng-deep
.channel-card {
  .tooltip-pii {
    @apply pointer-events-none w-48 py-[0.3rem] px-2 #{!important};

    .simpleText {
      @apply text-grey-300 text-xs #{!important};
    }
  }
}

.destinationPreviewListComponent {
  .noresultsContainer {
    .errorHeading {
      @apply text-lg mb-[10px];
    }
    .errorDetail {
      @apply text-[#a9b1c0]; //TODO: Remove hardcoded value
    }
  }
}

//ng-deep
.selected-destinations-list {
  .preselected {
    .poster {
      @apply border-2 border-solid border-state-error-300/50;
    }
    // .circle {
    //   @apply border-2 border-solid border-state-error-300/50;
    // }
  }
}

.destinationCard {
  .header {
    .new-status {
      .In-Progress {
        @apply bg-state-warning-1;
      }
      .Success {
        @apply bg-[#1b9696]; //TODO: FIX HARDCODED COLOR
      }
      .Failed {
        @apply bg-state-error-700;
      }
      .NoState {
        @apply bg-grey-400;
      }
      .Paused {
        @apply bg-grey-400;
      }
    }
    .status {
      &.active, 
      &.Active {
        @apply before:bg-state-success bg-[radial-gradient(circle_at_50%_50%,var(--tw-gradient-stops))] from-white/0 to-state-success/25;
      }
      &.approved {
        @apply before:bg-[#ffa21e] bg-[radial-gradient(circle_at_50%_50%,var(--tw-gradient-stops))] from-white/0 to-[#ffa21e]/25; //TODO: FIX THIS HARDCODED COLOR
        
      }
      &.requested {
        @apply before:bg-[#ffdd41] bg-[radial-gradient(circle_at_50%_50%,var(--tw-gradient-stops))] from-white/0 to-[#ffdd41]/25; //TODO: FIX THIS HARDCODED COLOR 
      }
      &.InProgress {
        @apply before:bg-state-warning-1 bg-[radial-gradient(circle_at_50%_50%,var(--tw-gradient-stops))] from-white/0 to-state-warning-1/25;
      }
      &.InActive,
      &.Inactive,
      &.inactive {
        @apply before:bg-state-error-700 bg-[radial-gradient(circle_at_50%_50%,var(--tw-gradient-stops))] from-white/0 to-state-error-700/25;
      }
      &.Failed,
      &.failed {
        @apply before:bg-state-error-700 bg-[radial-gradient(circle_at_50%_50%,var(--tw-gradient-stops))] from-white/0 to-state-error-700/25;
      }
      &.Paused,
      &.paused {
        @apply before:bg-grey-400 bg-[radial-gradient(circle_at_50%_50%,var(--tw-gradient-stops))] from-white/0 to-grey-400/25;
      }
    }
  }
}

//ng-deep
.destinationCard {
  .poster {
    @apply rounded border-none #{!important};
    @apply bg-white shadow-5; 
  }
  .poster-content {
    .element {
      .element-id {
        @apply text-grey/52 #{!important};
      }
      .id {
        @apply text-2xs;
      }
      .client-id {
        @apply text-grey-800 #{!important};
      }
    }
  }
  .tooltip-raw-pii {
    .simpleText {
      @apply text-grey-300 text-xs #{!important};
    }
  }
  .tool-tip-custom {
    @apply text-3xs #{!important}; 
  }
}

.destination-form-container {
  .customClose {
    @apply text-white text-xxs bg-[#afb6c4]; //TODO: FIX HARDCODED COLOR
  }
  .destinationPosition {
    .destinationInfo {
      .editIcon {
        @apply text-primary-1;
      }
    }
  }
}

//ng-deep
.destination-form-container {
  input::placeholder {
    @apply text-grey/52;
  }
  .ng-placeholder {
    @apply text-grey/52;
  }
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    .ng-value {
      @apply bg-[#ebebf0]; //TODO: REMOVE HARDCODED COLOR

      .ng-value-icon.right {
        @apply bg-grey/52;
      }
    }
  }
  .cancel-button {
    // color: var(--btn-text-disabled);
    @apply text-grey/52;
  }
  .actions-bar {
    @apply shadow-16;
  }
}

//ng-deep
.data-to-send-container {
  .droplist {
    .ng-select-container {
      .ng-placeholder {
        @apply text-grey/52 #{!important};
        @apply text-xs;
      }
    }
  }
  .mat-disabled {
    .mat-slide-toggle-bar {
      @apply bg-black/10 #{!important};
    }
    &.mat-checked .mat-slide-toggle-thumb {
      @apply bg-primary-1 #{!important};
    }
  }
  .error-dropdown-border {
    @apply border border-solid border-x-state-error-700 #{!important};
  }
}

//ng-deep
.unmapped-attribute {
  .mat-expansion-indicator {
    @apply relative #{!important};
    &::after {
      @apply text-primary-1 border-solid p-1 #{!important};
    }
  }
}

.destination-form-footer .custom-footer-container {
    @apply shadow-16 #{!important};
}

.destinationTray {
  @apply bg-grey-50 rounded #{!important};
  .innerContainer {
    .destinationCard {
      width: 13.4375rem;
    }
    .active-container {
      height: 14.4375rem;
    }
    .add-destination-container {
      @apply shadow-17 rounded border border-solid border-primary-1/[19] bg-primary-1/[0.02] #{!important};
      .add-destination-icon {
        @apply text-primary-1 #{!important};
      }
      .add-destination-text {
        @apply text-xs font-primary-medium text-center mt-5 #{!important};
      }
    }
  }
}

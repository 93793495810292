.apply-filter-button {
  .button-comp {
    @apply font-secondary-bold leading-4;
  }
}

.skip-filter-query-button {
  .button-comp {
    @apply font-secondary-bold leading-4;
  }
}

.active-next-button {
  .button-comp {
    @apply font-secondary-bold;
  }
}

.overlay {
  .error-text, .cancelbtn {
    @apply font-secondary-medium text-base;
  }
}

.segment-build-form-box {
  .step-marker {
    @apply font-secondary-medium;
  }
}

.form-box {
  .activation-description, .warning-message, .description, .description-link {
    @apply font-primary-italic;
  }
}

.action-bar {
  .skip-button {
    @apply font-secondary text-base;
  }
}

.modal {
  .modalheader, .info-text {
    @apply font-primary-medium;
  }
}

.skip-filter-query-button {
  .button-comp {
    @apply font-secondary-bold;
  }
}

.reload {
  .reloadIcon {
    @apply font-primary-light text-base mt-0 #{!important};
  }
}

.page-container {
  identifier-dropdown{
    .identifier-select-div{
      .close-icon-div {
        @apply bg-grey-200 text-primary-3-500 text-opacity-50;
      }
    }
  }
}

.identifier-list {
  .label-text  {
    @apply align-text-bottom h-[1.375rem] #{!important};
  }
}


.skip-filter-content {
  .tick-icon::before {
    @apply relative top-[0.187rem];
  }
}

.content-area {
  .form-box {
    .segment-id {
      .header {
        @apply text-grey-400/[70%];
      }
    }
    .optional-header {
      .optional {
        @apply text-grey-400/[70%];
      }
      .header {
        @apply text-primary-1-900/[80%];
      }
    }
    .input-header {
      @apply text-primary-1-900/[80%];
    }
  }
}

.page-container {
  .content-area {
    .form-box {
      .segment-id {
        .id {
          @apply text-base inline p-1 rounded-md text-primary-1-300 bg-primary-1-100;
        }
      }
      .disabledOption {
        @apply text-primary-3-500 text-opacity-50 #{!important};
      }
    }
  }
  .action-bar {
    .screen-info {
      @apply text-primary-3-500 text-opacity-50 w-[76px] inline-block;
    }
  }
}

.overlay-container { 
  .modal {
    .segment-id-desc {
      .segment-id {
        @apply text-primary-1-300 bg-primary-1-100;
      }
    }
    .action-row {
      .no-destination {
        @apply text-grey/52;
      }
    }
  }
}

.checkbox-div {
  zui-checkbox {
    .checkbox-comp {
      .icon::before {
        @apply relative top-1;
      }
    }
  }
}

.page-container {
  .create-button {
    &.btn-disabled {
      .button-comp {
        @apply bg-grey-400 text-grey/52 pointer-events-none shadow-none;
      }
    }
  }
}

.panel-width-full {
  .ng-dropdown-panel-items {
    @apply w-full #{!important};
  }
}


.help-center-container {
  @apply bg-primary-1-900/[70%];
}

.jumbo {
  zap-destination-tray {
    .destinationTray {
      zap-destination-card {
        .destinationCard {
          .active-container {
            height: 13.4375rem;
          }
        }
      }
    }
  }
}
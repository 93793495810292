.collapsable-panel-container {
	.mat-slide-toggle-thumb-container {
		@apply top-[2px] left-[2px] #{!important};
	}

	.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
		@apply left-2 #{!important};
	}

	.mat-slide-toggle-bar {
		@apply h-5 w-10 rounded-xl #{!important};
	}

	.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
		@apply bg-white #{!important};
	}

	.mat-slide-toggle-bar {
		@apply bg-white #{!important};
	}

	.mat-disabled .mat-slide-toggle-thumb {
		
	}
	.cdp-package {
		@apply bg-secondary-1-500/[0.12];

		.collapsable-dropdown-icon {
			@apply text-secondary-1-500;
		}

		.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
			@apply bg-secondary-1-500 #{!important};
		}

		.mat-slide-toggle-thumb {
			@apply bg-secondary-1-500 #{!important};
		}
	}

	.targeting-package {
		@apply bg-secondary-2-500/[0.12];

		.collapsable-dropdown-icon {
			@apply text-secondary-2-500;
		}

		.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
			@apply bg-secondary-2-500 #{!important};
		}

		.mat-slide-toggle-thumb {
			@apply bg-secondary-2-500 #{!important};
		}
	}

	.idp-package {
		@apply bg-secondary-3-500/[0.12];

		.collapsable-dropdown-icon {
			@apply text-secondary-3-500;
		}

		.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
			@apply bg-secondary-3-500 #{!important};
		}

		.mat-slide-toggle-thumb {
			@apply bg-secondary-3-500 #{!important};
		}
	}
}
.source-listing {
  .get-started {
    &.icon::before {
      @apply rotate-z-90 absolute -right-2.5;
    }
  }
  .input-comp {
    @apply min-h-[2.5rem] #{!important};
    .msgs-container {
      @apply min-h-0 #{!important};
    }
  }
  .unarchive {
    @apply text-primary-1 #{!important};
  }
  .archive-prompt-text .info-desc {
    @apply text-grey/68 text-xs;
    @apply leading-[0.875rem] #{!important};
    .archive-note {
      @apply mt-4;
    }
  }
  .informationIcon {
    @apply h-12 w-12 align-middle table-cell text-grey/52 rounded-[50%] bg-grey-100 border-[2.18182px] border-solid border-grey-300/[0.3];
  }
  .data-upload-modal {
    .container {
      @apply h-[30%] #{!important};
    }
  }
  .wrap-loader {
    @apply fixed left-0 top-0 #{!important};
  }
  .button-comp.floating {
    @apply inline-block;
    @apply mr-2.5;
    @apply h-10 w-10 min-w-[40px] #{!important};
  }
  .filterButton {
    .zui-button {
      @apply text-grey/52 bg-white rounded text-[1.37rem];
      .icon.filter {
        @apply mr-0;
      }

      .activeFilter {
        @apply absolute w-2 h-2 bg-primary-1 rounded-[50%] left-[1.9rem] top-[1.8rem];
      }
    }
    .zui-button:hover {
      @apply shadow-none #{!important};
    }
  }
  z-tree-header {
    .headerName {
      @apply font-secondary-bold uppercase text-sm font-bold text-grey/52 align-bottom;
    }
  }
  z-tree-content {
    .filterRow .filterName.zui-boldtext {
      @apply font-primary-medium;
    }
  }
  .clearText {
    @apply uppercase font-secondary right-2;
  }
}

.source-details-wrapper {
  .icon.next::before {
    vertical-align: top;
    transform: rotateY(180deg);
    cursor: pointer;
  }
}


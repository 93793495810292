.request-access-list.ngx-datatable {
  @apply w-full;
  .datatable-header {
    @apply w-full pl-0;
    .datatable-header-inner {
      @apply w-full #{!important};
      .datatable-row-center {
        @apply w-full #{!important};
        .datatable-header-cell {
          @apply text-[0.6875rem];
          @media screen and (max-width: 1024px) {
            @apply px-2 py-[0.9rem];
          }
        }
      }
    }
  }
  .resize-handle {
    border-right: 0px !important;
  }
  .datatable-row-group {
    @apply border-b-[0.06rem] border-solid border-grey-150 hover:bg-white #{!important};
    &:hover {
      transition-duration: 0s !important;
    }
  }
  .datatable-body-cell-label {
    @apply overflow-hidden text-ellipsis mx-0 my-auto text-grey/52;
  }
  .datatable-body {
    @apply w-full #{!important};
    .datatable-scroll {
      @apply w-full #{!important};
      .datatable-row-wrapper {
        @apply w-full pl-0;
        .datatable-body-row {
          @apply cursor-pointer;
          @apply w-full #{!important};
          .datatable-row-center {
            @apply w-full #{!important};
            .datatable-body-cell {
              @apply text-[0.8125rem];
              @media screen and (max-width: 1024px) {
                @apply px-2 py-[0.9rem];
              }
            }
          }
        }
      }
    }
  }
  .approved::before {
    @apply bg-state-success;
  }
  .sent::before,.pending::before {
    @apply bg-state-warning-1;
  }
  .revoked::before {
    @apply bg-grey-400;
  }
  .rejected::before {
    @apply bg-state-error-700;
  }
  .request-actions {
    width: 9rem !important;
    max-width: 9rem !important;
    .action {
      @apply text-grey-800 font-secondary uppercase;
    }
  }
}

.request-actions {
  @apply w-[9rem] max-w-[12rem] #{!important};
  .action {
    @apply text-grey-800 font-secondary uppercase;
  }
}
.audience-ngx-datatable {
  .datatable-row-even {
    @apply bg-primary-1/[0.02];
  }
}

.audience-channel-list {
  .sideOverlay-container {
    @apply max-w-2xl min-w-[32rem];
    .overflow-y-scroll {
      @apply bg-white;
    }
    .header-div {
      @apply grid grid-cols-[1fr_1.875rem] #{!important};
    }
  }
  .destination-form-container {
    .main-modal {
      @apply px-0;
    }
  }
  circle {
    @apply stroke-white;
  }
}

.insights-input-details-modal{
  .sideOverlay-container {
    @apply w-[35%];
    .body-section {
      @apply bg-white h-full #{!important} ;
    }
    .header-div{
      @apply grid grid-cols-[1fr_1.875rem] #{!important};
    }
  }
}
.insights-destination-details-modal{
  .sideOverlay-container {
    .header-label {
      @apply overflow-hidden #{!important};
    }
    @apply w-[35%];
    .body-section {
      @apply bg-white h-full #{!important};
    }
    .header-div{
      @apply grid grid-cols-[1fr_1.875rem] #{!important};
    }
  }
}

.setting-modal {
  .sideOverlay-container {
    @apply w-[35%];
    .header-div {
      @apply grid grid-cols-[1fr_1.875rem] #{!important};
    }
    .body-section {
      @apply bg-white #{!important};
    }
    .header-label {
      @apply flex items-center gap-x-2;
    }
  }
}

@media only screen and (max-width: 1250px){
  .sideOverlay-container {
    @apply w-[45%] #{!important};
  }
}

@media only screen and (min-width: 1250px) and (max-width: 1450px){
  .sideOverlay-container {
    @apply w-[40%] #{!important};
  }
}

// AudienceRefreshComponent styles
.refresh-container {
  &.disabled-inputs {
    .ng-select-container {
      @apply bg-grey-100 cursor-not-allowed pointer-events-none #{!important};
    }
    .mat-form-field-wrapper {
      @apply bg-grey-100 cursor-not-allowed pointer-events-none #{!important};
    }
  }
  .checkbox-comp {
    @apply p-0 #{!important};
    .label-text {
      @apply font-primary-medium font-bold #{!important};
    }
  }
  .ng-select-container {
    @apply h-full #{!important};
    .ng-input {
      top: unset !important;
    }
  }
  .calendar-option {
    .mat-form-field-wrapper {
      @apply pb-0;
    }
    .mat-form-field-flex {
      @apply pt-2 px-2 pb-0;
      background: none;
    }
    .mat-form-field-underline {
      @apply hidden;
    }
    .mat-datepicker-toggle-active {
      @apply text-primary-1 #{!important};
    }
  }
}

.mat-calendar-body-selected {
  @apply bg-primary-1 #{!important};
}

.estimator-section {
  .sideOverlay-container {
    @apply w-[35%];
    .header-div {
      @apply grid grid-cols-[1fr_1.875rem] #{!important};
    }
  }
  .body-section {
    @apply bg-white h-[calc(100%_-_3rem)] #{!important};
  }
  zap-status {
    .dots {
      @apply pt-2;
    }
  }
}
.ab-switch {
  .label-text {
    @apply ml-3 text-primary-1 font-primary-medium font-bold #{!important};
  }
}

.loader-white-stroke {
  circle {
    @apply stroke-white;
  }
}

.tag-selection-dropdown {
  &.create-tag {
    .ng-dropdown-header {
      @apply h-16 #{!important};
    }
  }
  .ng-dropdown-header {
    @apply h-10 #{!important};
  }
}

.estimator-tooltip {
  @apply w-max min-w-[3rem] p-2 flex justify-center #{!important};
  .simpleText {
    @apply text-xs #{!important};
  }
}

.insights-loader-reload{
  .reloadIcon {
    @apply mt-0 #{!important}
  }
}

.audience-destination-cohort {
  .secondary-1-700 {
    @apply text-secondary-1-700 bg-secondary-1-700/[0.12];
  }

  .secondary-2-700 {
    @apply text-secondary-2-700 bg-secondary-2-700/[0.12];
  }

  .secondary-3-700 {
    @apply text-secondary-3-700 bg-secondary-3-700/[0.12];
  }

  .primary-2-500 {
    @apply text-primary-2-500 bg-primary-2-500/[0.12];
  }

  .secondary-2-500 {
    @apply text-secondary-2-500 bg-secondary-2-500/[0.12];
  }

  .primary-2-300 {
    @apply text-primary-2-300 bg-primary-2-300/[0.12];
  }
}

.audience-cohort-card {
  &.br-secondary-1-700 {
    @apply border-secondary-1-700;
  }

  &.br-secondary-2-700 {
    @apply border-secondary-2-700;
  }

  &.br-secondary-3-700 {
    @apply border-secondary-3-700;
  }

  &.br-primary-2-500 {
    @apply border-primary-2-500;
  }

  &.br-secondary-2-500 {
    @apply border-secondary-2-500;
  }

  &.br-primary-2-300 {
    @apply border-primary-2-300;
  }

  .tx-secondary-1-700 {
    @apply text-secondary-1-700;
  }

  .tx-secondary-2-700 {
    @apply text-secondary-2-700;
  }

  .tx-secondary-3-700 {
    @apply text-secondary-3-700;
  }

  .tx-primary-2-500 {
    @apply text-primary-2-500;
  }

  .tx-secondary-2-500 {
    @apply text-secondary-2-500;
  }

  .tx-primary-2-300 {
    @apply text-primary-2-300;
  }
}

.estimator-error-tooltip {
  @apply w-[14rem] break-words p-2 flex justify-center bg-state-error-700 #{!important};
  .simpleText {
    @apply text-xs #{!important};
  }
}

.estimator-pie-chart {
  div.advanced-pie-legend-wrapper {
    @apply hidden w-0 h-0 #{!important};
  }
  .advanced-pie {
    @apply relative top-[.625rem] #{!important};
  }
}

.destination-status-tooltip {
  @apply w-[17rem] px-4 py-2 #{!important};
}

.status-container {
  .bgc-in-progress {
    @apply bg-state-warning-1;
  }
  .bgc-active {
    @apply bg-state-success;
  }
  .bgc-success {
    @apply bg-state-success;
  }
  .bgc-failed {
    @apply bg-state-error;
  }
  .txt-in-progress {
    @apply text-state-warning-1;
  }
  .txt-active {
    @apply text-state-success;
  }
  .txt-success {
    @apply text-state-success;
  }
  .txt-failed {
    @apply text-state-error;
  }
}
.insights-pi-chart-legend{
  .legend-color-palette-1 {
    @apply bg-state-warning-1-500;
  }

  .legend-color-palette-2 {
    @apply bg-secondary-3-500;
  }

  .legend-color-palette-3 {
    @apply bg-secondary-2-700;
  }

  .legend-color-palette-4 {
    @apply bg-state-success-700;
  }

  .legend-color-palette-5 {
    @apply bg-state-warning-2-500;
  }

  .legend-color-palette-6 {
    @apply bg-secondary-1-500;
  }
}

.insights-metric-container {
  .ng-select-container {
    @apply h-10 bg-primary-1 text-white #{!important};
  }
  .ng-select.ng-select-opened>.ng-select-container .ng-arrow{
    @apply border-[transparent_transparent_white] #{!important};
  }
  .ng-select .ng-arrow-wrapper .ng-arrow{
    @apply border-[white_transparent_transparent] #{!important};
  }
}

.audience-destination-drop-container {
  .clipboard {
    @apply flex items-center #{!important};
  }
}

.audience-query-block {
  .form-control[disabled] {
    @apply bg-white #{!important};
  }
  .ng-select.ng-select-disabled>.ng-select-container {
    @apply bg-white #{!important};
  }
  .ng-select.ng-select-disabled .ng-arrow-wrapper {
    @apply hidden #{!important};
  }
}

.mat-calendar-body-cell:focus .mat-focus-indicator::before{
  @apply content-[normal] #{!important};
}
.audience-mapping-dropdown {
  &.ng-select.ng-select-theme .ng-dropdown-header {
    @apply flex items-center h-10 px-6 py-4 border-b border-solid border-grey-250 #{!important};
  }
}
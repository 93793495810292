ng-select {
  .ng-select-theme.ng-select-opened {
    .ng-select-container {
      @apply border-2 border-solid;
      @apply border-primary-1 #{!important};
    }
  }

  // Our default ng-select theme
  &.ng-select-theme {
    .ng-select-container {
      color: #011644 !important;
      border-color: #c1c1c5;
      box-shadow: none !important;
    }

    .ng-arrow {
      @apply border-[grey/52_transparent_transparent] #{!important};
    }

    .ng-option.ng-option-selected.ng-option-marked {
      @apply text-grey-900 bg-grey-500/20;
    }

    .ng-dropdown-panel.ng-select-bottom {
      margin-top: 0px;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
      @apply text-grey-900 bg-grey-500/20;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
      @apply text-grey-900 bg-grey-500/20;
    }

    .ng-placeholder {
      @apply top-[unset] #{!important};
    }

    .ng-input {
      @apply top-[unset] #{!important};
    }

    .option-holder {
      @apply flex items-center;

      input[type="checkbox"] {
        @apply my-0 ml-0 mr-[0.625rem] #{!important};
      }
    }

    .ng-dropdown-header {
      @apply h-[1.37rem] #{!important};
    }
  }

  &.disabled-secondary {
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
      @apply bg-white text-grey-400 cursor-not-allowed #{!important};
    }
  }

  // Add this preset to hide clear button in ng-select
  &.hide-clear-btn {
    .ng-clear-wrapper {
      @apply hidden #{!important};
    }
  }

  // Add this preset for custom labels
  &.custom-labels-preset {
    .custom-ng-value {
      @apply flex items-center h-[1.625rem] max-w-[11rem];
      @apply rounded-2xl bg-grey-50 border-[0.0625rem] border-solid border-grey-300/30 text-sm #{!important};

      .custom-ng-icon {
        @apply text-2xs border border-solid border-grey-300/30 text-grey/52 opacity-100 rounded-[50%];
        @apply bg-grey-50 p-1 #{!important};
      }

      .ng-value-label {
        @apply whitespace-nowrap overflow-hidden text-ellipsis;
      }
    }

    .custom-ng-more {
      @apply bg-opacity-0 text-sm mr-0 #{!important};
    }
  }
  &.ng-select-theme-xl {
    @apply text-base;

    .ng-select-container {
      height: 4rem !important;
      width: inherit;
      border-radius: 0.25rem 0 0 0.25rem;
      @apply border-grey-300/30;
    }
    .ng-value-container {
      font-size: 1rem;
    }
    .ng-dropdown-panel {
      width: 100%;
    }
    .ng-placeholder {
      top: unset !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .ng-input {
      top: unset !important;
    }
    .ng-dropdown-panel {
      padding: 0.3125rem 0;
    }
    .ng-dropdown-panel-items {
      max-height: 12.5rem;
    }
    .ng-dropdown-header {
      height: 1.37rem;
    }
    .ng-tag-label {
      font-size: 0.875rem;
      margin: 0.8125rem 1.4375rem 0 0;
      @apply text-primary-1;
    }
    .ng-option {
      padding-left: 1.625rem;
    }
    .option-holder {
      display: flex;
      align-items: center;
      input[type="checkbox"] {
        margin: 0 0.625rem 0 0 !important;
      }
    }
    .ng-value {
      font-size: 1rem;
      white-space: pre !important;
    }
    .custom-ng-value {
      @apply bg-grey-50 shadow-1;
      display: flex;
      align-items: center;
      border-radius: 0.25rem !important;
      font-size: 0.875rem !important;
      height: 1.625rem;
      max-width: 11rem;

      .ng-value-label {
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .custom-ng-icon {
        @apply border-grey-300/30 text-grey/52;

        font-size: 0.625rem;
        padding: 0.25rem !important;
        border-radius: 50%;
        opacity: 1;
      }
    }
    .custom-ng-more {
      font-size: 0.875rem !important;
      @apply text-primary-1;
      background: none !important;
      margin-right: 0 !important;
    }
  }

  &.hide-arrow {
    .ng-arrow-wrapper {
      display: none !important;
    }
  }
  &.remove-option-padding {
    .ng-option {
      padding: 0 !important;
    }
  }
}

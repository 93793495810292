.segment-table {
  .mat-tooltip {
    @apply text-white bg-grey-800 rounded font-primary text-xs leading-4 #{!important};
  }
  .ngx-datatable {
    .datatable-row-group {
      @apply border-b-[0.06rem] border-solid border-grey-150 hover:bg-white #{!important};
      &:hover {
        transition-duration: 0s !important;
      }
    }
    .datatable-body-cell-label {
      @apply overflow-hidden text-ellipsis mx-0 my-auto text-grey/52;
    }
    .datatable-body-cell {
      @apply cursor-pointer;
    }
  }
}

.new-poster-container {
  .poster {
    @apply shadow-22 hover:shadow-23 #{!important};
  }
}

.calendar-option {
  .mat-calendar-body-cell:focus .mat-focus-indicator::before{
    @apply content-[normal] #{!important};
  }
}

.segments-container {
  .ngx-datatable.material .datatable-header {
    @apply text-grey-400 bg-grey-50 font-secondary border-b-0 #{!important};
  }
  .datatable-body, .material {
    @apply bg-grey-50 #{!important};
  }
}

.chart-container {
  .value {
    @apply fill-grey-400 capitalize #{!important};
  }
}

.tree-map-container {
  @apply w-full h-full min-h-[26.12rem];
  .panels {
    @apply grid grid-cols-[1fr_10rem];
    .navigation-panel {
      @apply text-grey-800 h-5 ml-[0.7rem] mt-4;
      .frame-header  {
        @apply text-base align-text-top pointer-events-none mt-4;
      }
      .show-cursor {
        @apply cursor-pointer pointer-events-auto;
      }
    }
    .child-count {
      @apply flex text-state-success-500 font-secondary-bold flex-row-reverse text-base mr-4 mt-4;
    }
  }
  .ngx-charts {
    foreignObject {
      p {
        @apply font-secondary;
      }
    }
  }
}

.org-data-input, .country-data-input {
  .input-comp {
    @apply w-[17rem] #{!important};
  }
}

.country-data-input {
  .main-container {
    .control {
      @apply w-60 text-ellipsis whitespace-nowrap overflow-hidden pt-3.5 left-[1.5625rem];
    }
  }
}

.datatable-body .datatable-row-center{
  &:hover {
    @apply bg-primary-1-100 rounded-md #{!important};
  }
}

.selected-segment-table { 
  .datatable-row-wrapper {
    @apply bg-primary-1-100;
  }
}

.page-container {
  .content-area {
    .form-box {
      .segment-id {
        .id {
          @apply text-base inline p-1 rounded-md text-primary-1-300 bg-primary-1-100;
        }
      }
    }
  }
}


.add-segment-button{
  .button-comp {
    &[disabled] {
      @apply bg-grey-400 text-grey/52 pointer-events-none shadow-none;
    }
    &:hover {
      @apply shadow-none #{!important};
    }
  }
}

.segment-table-tooltip {
  @apply w-max py-2 px-4 #{!important};
  .simpleText {
    @apply text-xs #{!important};
  }
}


.basic-details-container {
  .Licensed {
    @apply bg-secondary-3-100/40 text-secondary-3-100;
  }
}

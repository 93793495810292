//TAILWIND_REFACTORED
:host .source-details-containerr ::ng-deep {
  zap-no-content-image-text .empty-container {
    height: calc(70vh - 1.5rem);
  }
  //   zap-mapping-view-list .mapping-table-body {
  //     @apply h-[70vh];
  //   }
}

.source-details-item {
  .source-title {
    @apply font-bold pb-[0.375rem] text-base overflow-hidden whitespace-nowrap cursor-default text-ellipsis;
  }
  .source-item-container {
    @apply shadow-none w-auto py-5 pr-0 pl-5 min-w-[5rem] h-[70px];
    .source-icon-container {
      @apply mr-4;
    }
  }
  .source-info {
    @apply ml-0;
  }
  .hover-action-button {
    @apply hidden;
  }
  .source-item-container:hover {
    @apply cursor-default;
    .hover-action-button {
      @apply hidden;
    }
  }
}

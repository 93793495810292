@import './../rbac-core/styles/rbac-core.scss';
@import './../org-create/styles/org-create.scss';
@import './../rbac-orgs/styles/rbac-orgs.scss';

.org-dropdown {
	.ng-select-container {
		@apply h-12 #{!important};
	}

	&.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
		@apply top-3 #{!important};
	}

	&.ng-select .ng-spinner-loader {
		@apply border-l-primary-1 border-t-primary-1/10 border-b-primary-1/10 border-r-primary-1/10 #{!important};
	}
}

.disable-dropdown {
  .org-dropdown {
    .ng-select-container {
      @apply bg-grey-400 #{!important};
    }
    .ng-arrow-wrapper {
      @apply hidden #{!important};
    }
  }
}

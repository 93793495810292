.key-value-input-list-container {
  .disabled {
    .ng-arrow-wrapper {
      @apply hidden;
    }
    .ng-select-container {
      @apply bg-grey-100;
    }
  }

  .close {
    @apply text-[0.62rem] rounded bg-grey-100 text-[#6c7185] opacity-100 float-none pb-[0.187rem] p-1;
  }
  .ng-select {
    @apply rounded;
  }
  .ng-select-container {
    @apply h-10 #{!important};
    .ng-value-container .ng-input > input {
      @apply pt-1;
    }
    .ng-clear-wrapper {
      @apply hidden #{!important};
    }
  }
  .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    @apply bg-primary-1 #{!important};
  }
}

// .content {
//     @apply min-h-0 h-[2.45rem];
// }

.key-value-dropdown {
  .ng-dropdown-panel-items {
    @apply max-h-[12.5rem] #{!important};
  }
}

//TAILWIND_REFACTORED
zap-enricher {
  .date-list .ng-dropdown-panel {
    @apply w-[19rem];
  }
  .check {
    @apply block h-[25px] w-[25px] z-[5] transition-[border] duration-[0.25s] ease-linear rounded-[100%] border-[5px] left-5 top-[30px] border-solid border-white before:block before:content-[""] before:h-[15px] before:w-[15px] before:transition-[background] before:duration-[0.25s] before:ease-linear before:m-auto before:rounded-[100%] before:left-[5px] before:top-[5px];
  }  
}

custom-tool-tip {
  .tooltip-enricher {
    @apply min-w-[14rem] max-w-[18rem];
    @apply w-max leading-4 px-2.5 py-[0.3125rem] #{!important};
  }
  .tooltip-enricher .error-container {
    @apply flex;
  }
  .tooltip-enricher .error-container .error {
    @apply text-state-error-700;
  }
  .tooltip-enricher .error-container .error-text {
    @apply text-xs text-white;
  }
  .tooltip-enricher .error-container .info-icon-tooltip {
    @apply text-grey/52 w-4 h-4 pl-[0.1875rem] rounded-[50%] bg-grey-50;
  }
}

//TAILWIND_REFACTORED
.create-attribute {
  .save-btn {
    @apply text-primary-1;

    &:hover {
      @apply bg-primary-1/10;
    }
  }
}

.attribute-subtext {
  @apply text-xs fixed text-grey/52 bg-white h-8 z-10;
}

zap-calculated-attribute-create {
  .wrap-loader {
    @apply left-0 pointer-events-none;
    @apply z-[1000] #{!important};
  }

  zap-modal-holder {
    .sideOverlay-container {
      @apply w-[52%] #{!important};
      @apply min-w-[40rem];

      .header-box {
        @apply shadow-none;
        @apply h-[6.25rem] #{!important};
      }

      .header-label {
        @apply pl-14 pr-6 pt-[1.4375rem] pb-4 normal-case #{!important};

        div {
          @apply leading-[0.9075rem] mb-2;
        }
      }
    }

    .sub-header {
      @apply text-xs text-grey/52;
    }

    .body-section {
      @apply py-0 pr-6 pl-14;
      @apply bg-white h-[calc(100%_-_10.25rem)] #{!important};
    }

    .footer {
      @apply shadow-1 #{!important};
    }
  }

  .standard-input {

    &::placeholder,
    &::-webkit-input-placeholder {
      @apply not-italic #{!important};
      @apply overflow-hidden whitespace-nowrap text-ellipsis;
    }
  }

  .error-datepicker-border .mat-form-field-flex {
    @apply border border-solid border-state-error-700 #{!important};
  }

  .add-attribute-form-container {
    @apply flex flex-col rounded gap-8 h-full overflow-y-scroll;

    .input-label {
      @apply text-grey-800 text-xs mb-[0.625rem] w-max;
    }

    .droplist {
      @apply w-[28rem] bg-grey-50;

      .ng-select-container {
        @apply h-10 #{!important};
        @apply w-[inherit] bg-grey-50 border border-solid border-grey-300;
      }

      .ng-dropdown-panel {
        @apply min-w-max w-[inherit];
      }

      .ng-placeholder {
        @apply top-[unset] #{!important};
        @apply overflow-hidden whitespace-nowrap text-ellipsis;
      }

      .timePeriod {
        @apply pb-4;
      }

      .ng-dropdown-panel-items {
        @apply max-h-[12.5rem];
      }

      .ng-dropdown-header {
        @apply h-[1.5rem] #{!important};
      }

      .ng-value {
        @apply whitespace-pre #{!important};
      }

      .ng-input {
        @apply top-[0.625rem] #{!important};
      }

      .custom-ng-value {
        @apply flex items-center h-[1.625rem] max-w-[11rem] shadow-1;
        @apply bg-white text-sm #{!important};

        .ng-value-label {
          @apply whitespace-pre w-24 overflow-hidden text-ellipsis;
        }

        .custom-ng-icon {
          @apply text-2xs border border-solid border-grey-300/[0.3] rounded-[50%] text-grey/52 opacity-100;
          @apply p-1 bg-grey-50 #{!important};
        }
      }

      .custom-ng-more {
        @apply text-sm text-primary-1;
        @apply bg-none mr-0 #{!important};
      }
    }

    .definition {
      @apply flex flex-col gap-8;
    }

    .flex-aggr {
      @apply flex gap-4 items-baseline flex-wrap;
    }

    .timePeriod,
    .period {
      @apply flex items-center gap-[0.625rem];
    }

    .timePeriod {
      @apply pb-8;
    }

    .time-period-label {
      @apply mb-0 #{!important};
    }

    .duration {
      @apply w-44;
    }

    .timePeriod-type {
      @apply w-40;
    }

    .aggregation-type {
      @apply w-60;
    }

    .aggregation-on {
      @apply w-48;
    }

    .queryContainer {
      @apply border border-solid border-grey-300 rounded p-4 w-[calc(100%_-_2rem)];
    }

    .event-name {
      @apply w-[23rem];
    }

    .total {
      @apply w-20;
    }

    .disabled {
      @apply bg-grey-100;

      .ng-arrow-wrapper {
        @apply hidden #{!important};
      }

      .ng-select-container {
        @apply bg-grey-100;
      }
    }

    textarea:disabled {
      @apply bg-grey-100;
    }

    .no-pointer {
      @apply cursor-default;
    }

    .drop-down-item {
      @apply align-bottom;
    }

    .description {
      @apply h-16 bg-grey-50 border border-solid border-grey-300 rounded w-[calc(100%_-_2rem)] p-[0.875rem] resize-none overflow-y-scroll leading-[1.0625rem];

      &:focus-visible {
        @apply outline-none border border-solid border-primary-1;
      }

      &::placeholder,
      &::-webkit-input-placeholder {
        @apply text-grey/52;
      }
    }

    .component-loader {
      @apply h-full;
    }
  }

  .error-dropdown-border .ng-select-container {
    @apply border-[0.0625rem] border-solid border-state-error-700 #{!important};
  }

  .range {
    @apply text-sm #{!important};

    .mat-form-field-wrapper {
      @apply pb-0;
    }

    .mat-form-field-flex {
      @apply items-center border border-solid border-grey-300 h-10 rounded w-40 bg-grey-50;
      @apply py-0 px-2 #{!important};
    }

    .mat-form-field-underline {
      @apply hidden;
    }

    .mat-form-field-infix {
      @apply border-t-0 p-0 #{!important};
    }

    input.mat-input-element {
      @apply text-center;
    }

    .mat-input-element:disabled {
      @apply text-grey-900;
    }
  }

  .range.disabled .mat-form-field-flex {
    @apply bg-grey-100;
  }

  .mat-calendar-body-selected {
    @apply bg-primary-1 #{!important};
  }

  .qb-loader-holder {
    @apply top-0 left-0;
  }
}

.attribute-input-validation {
  @apply min-w-[14rem] max-w-[18rem] w-max leading-4 py-[0.3125rem] px-[0.625rem] #{!important};

  .error {
    @apply text-state-error-700;
  }

  .error-text {
    @apply text-xs text-white;
  }
}
.event-name-info {
  @apply w-max leading-4 py-[0.3125rem] px-[0.625rem] #{!important};
}

:host {
  input[type='file'] {
    display: none;
  }
}

zap-file-uploader {
  &.file-uploader-preset-1 {
    .file-upload-container {
      @apply mt-[-0.1rem] ml-10 mr-0 mb-0 w-[70%];
    }
    .file-list-section {
      @apply w-[70%];
    }
    .file-list {
      @apply mt-[-0.12rem] ml-10 mr-0 mb-0 w-full p-0;
    }
    .upload-bg {
      @apply ml-10 bg-grey-50 rounded-[0_0_0.25rem_0.25rem] border-solid border-grey-300/[0.3] border p-[0.625rem] w-full;
    }
    .upload-files {
      @apply h-8 bg-grey-500/10;
    }
    .file-item-container {
      @apply border border-solid border-grey-300/[0.3] grid-cols-[0.07fr_0.7fr_0.5fr_0.075fr] bg-grey-50;
    }
  }
}

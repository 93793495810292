zap-consent-mapper{
  .consent-container {
    @apply pt-4 pb-8 px-0;
  }

  .consent-container .mapper-header {
    @apply border-b border-solid border-grey-300/30 text-base text-grey-800 pl-8 pr-4 pt-0 pb-4;
  }

  .consent-container .mapping-row {
    @apply grid grid-cols-[minmax(0,0.5fr)_0.2fr_0.7fr_0.3fr_0.3fr] border-b border-solid border-grey-300/30 gap-2 items-center pl-8 pr-[1.3rem] py-[1.3rem];
  }

  .side-arrow::before {
    @apply rotate-90;
  }

  .side-arrow {
    @apply text-grey-300 font-extrabold justify-self-center;
  }

  .add-btns-container {
    @apply flex gap-4 ml-8 mt-4;
  }

  .add-mapping,
  .custom-field-btn {
    @apply text-sm self-center rounded cursor-pointer w-max p-1.5;
  }

  .add-mapping:hover,
  .custom-field-btn:hover {
    @apply bg-primary-1/10;
  }

  .custom-field-label {
    @apply text-grey/52;
  }

  .ng-custom-option {
    @apply flex flex-row items-baseline;
  }

  .ng-option-name {
    @apply ml-2.5;
  }

  .consent-fields .ng-value-label .field-name-container {
    @apply max-w-[18.5rem];
  }

  .ng-value-label {
    @apply max-w-xs text-ellipsis overflow-hidden rounded-2xl bg-grey-50 px-3 #{!important};
  }

  .custom-ng-value {
    @apply flex flex-row items-baseline border border-grey-300/30 box-border border-solid;
    @apply bg-grey-50 rounded-2xl p-[0.15rem] #{!important};
  }

  .custom-ng-value .ng-value-icon {
    @apply text-2xs border-grey-300/30 box-border text-grey/52 opacity-80 p-1 rounded-[50%] border-[0.5px] border-solid hover:opacity-100;
    @apply bg-grey-100 #{!important};
  }

  .custom-ng-value .ng-value-icon ::before {
    @apply drop-shadow-none;
  }

  .arrow-mark {
    @apply self-center justify-self-center col-start-2;
  }

  .arrow-mark .error,
  .error-container {
    @apply text-state-error-700;
  }

  .error-container {
    @apply text-xs flex gap-[0.3125rem] pt-2 pb-0 px-2;
  }

  .remove-mapping {
    @apply grid justify-center;
  }

  .enricher {
    @apply flex flex-row justify-center items-center col-start-4;
  }

  .enricher-button {
    @apply flex justify-center items-center cursor-pointer text-sm;
  }

  .add-enricher-new-btn {
    @apply text-state-error-700 hover:bg-state-error-700/10;
  }

  .edit-enricher-btn {
    @apply border border-solid border-grey-300/30 box-border rounded text-grey/68;
    @apply bg-grey-50 #{!important};
  }

  .edit-enricher-btn,
  .add-enricher-new-btn {
    @apply w-max rounded leading-4 p-1.5;
  }

  .delete-action {
    @apply cursor-pointer h-5 w-5 text-xl rounded self-center bg-state-error-700/10 text-state-error-700;
  }

  .ng-select .ng-select-container {
    @apply h-max;
    @apply min-h-[2.5rem] #{!important};
  }

  .ng-placeholder {
    @apply top-[unset] #{!important};
  }

  .ng-input {
    @apply top-[unset] #{!important};
  }
}

.consent-mapping-droplist {
  .ng-value {
    @apply z-10 #{!important};
  }
}
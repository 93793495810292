//TAILWIND_REFACTORED
.search-profile {
  ng-select {
    .ng-select-container {
      @apply bg-grey-50 rounded-l rounded-r-none h-10 w-[12.5rem] #{!important};
    }
  }
  .search-input {
    .main-container {
      @apply bg-grey-50 rounded-l-none rounded-r h-10 #{!important};
    }
    .msgs-container {
      @apply hidden;
    }
    .input-comp {
      @apply min-h-0;
    }
  }
}

.tooltip-text-2 {
  @apply w-max p-2 ml-[0.8rem] #{!important};
}

.clipboard {
  display: inline-block;
  position: relative;
  bottom: 2px;
  width: 20px;
  height: 20px;
  .copyBtn {
    background-color: none;
    border-radius: 4px;
    font-size: 14px;
    padding: 3px 3px 1px 3px;
  }
}

zap-clipboard-copy {
  &.copy-with-grey-bg {
    .clipboard {
      @apply cursor-pointer;
      .copyBtn {
        @apply px-1 pt-1 pb-[0.12px] bg-grey-100;
      }
    }
  }
}

zap-toggle-window {
    &.three-windows {
    @apply justify-center flex w-full #{!important};
    &.two-grid .toggle-window-container {
        @apply grid-cols-[1fr_1fr] #{!important};
    }
    .toggle-window-container {
        background-color: #dadada; // TODO: update
        @apply shadow-none grid grid-cols-[1fr_1fr_1fr] p-1 rounded-[100px] #{!important};
        .option.activeOption {
            @apply shadow-none rounded-[100px] #{!important};
            .primary-text,
            .secondary-text,
            .blacklisted-text {
                @apply text-white #{!important};
            }
        }
        .option {
            @apply px-6 py-2 flex justify-center #{!important};
            .option-text {
                color: #313852 !important; // TODO: update
                @apply text-sm font-medium leading-4 #{!important};
            }
        }
    }
    .primary .activeOption {
        background-color: #31b970 !important; // TODO: update
    }
    .secondary .activeOption {
        @apply bg-primary-1 #{!important};
    }
    .blacklisted .activeOption {
        @apply bg-grey/52 #{!important};
    }
}
}

/**
 * overrides
 */
.zui-stepper-wrapper {
  .zui-step {
    .zui-step-icon {
      .completed.icon.tick::before {
        @apply w-6 h-6 leading-6 text-center rounded-3xl bg-grey-800 text-white;
      }
    }
  }
}

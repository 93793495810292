//TAILWIND_REFACTORED
zap-derived-value-enricher{
    .derived-value-container {
        @apply flex flex-col;
    }
    :host ::placeholder {
        @apply text-grey/52 #{!important};
    }
    .arrow-container {
        @apply text-center;
    }
    .arrow-container .side-arrow::before{
        @apply rotate-z-90;
    }
    .arrow-container .side-arrow{
        @apply text-grey-300/30 font-extrabold;
    }
    .add-condition {
        @apply flex justify-center h-[5.375rem] items-center text-sm cursor-pointer;
    }
    .combination-query, .add-condition {
        @apply bg-grey-50 border border-solid border-grey-300/30 rounded;
    }
}
.qb-item-select.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-disabled {
  @apply pointer-events-none cursor-not-allowed bg-grey-100;
}
.qb-tooltip-enricher {
  @apply w-[11.5625rem] leading-4 pl-1.5 pr-3 py-1.5;
}
.qb-tooltip-enricher .error-container {
  @apply flex;
}
.qb-tooltip-enricher .error-container .error {
  @apply text-sm text-state-error-700;
}
.qb-tooltip-enricher .error-container .error-text {
  @apply text-xs leading-[0.9rem] text-white ml-[0.475rem];
}
.custom-moving-window-title {
  @apply text-grey/52;
}

.custom-moving-window-input {
  @apply text-primary-1-900;
  input {
    @apply border border-solid border-grey-300 focus:border-primary-1;
  }
}
.q-empty-warning {
  @apply text-state-error-500 font-primary-medium #{!important};
}
.q-connector {
  @apply ml-6 relative;
  &::before,
  &::after {
    @apply border-state-warning-1 #{!important};
  }
}
.and.q-connector {
  @apply ml-6;
  &::before,
  &::after {
    @apply border-state-warning-1 #{!important};
  }
}
.or.q-connector {
  @apply ml-6;
  &::before,
  &::after {
    @apply border-state-success #{!important};
  }
}
.q-ruleset,
.and.q-ruleset,
.q-rule,
.and.q-rule {
  @apply bg-state-warning-1/5 #{!important};
  @apply border-state-warning-1/10 #{!important};
  @apply rounded-[.25rem] #{!important};
}
.or.q-ruleset,
.or.q-rule {
  @apply bg-state-success/5 #{!important};
  @apply border-state-success/10 #{!important};
  @apply rounded-[.25rem] #{!important};
}
.and.q-invalid-ruleset,
.or.q-invalid-ruleset {
  @apply bg-state-error-500/5 #{!important};
  @apply border-state-error-500/10 #{!important};
}

.query-builder mat-radio-button.mat-radio-checked {
  .mat-radio-outer-circle {
    @apply border-primary-1 #{!important};
  }
  .mat-radio-inner-circle {
    @apply bg-primary-1 #{!important};
  }
}
.add-button {
  @apply text-primary-1;
}

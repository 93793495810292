  //this has not been imported to any styles.scss like it has been done in unity/styles.scss
  @font-face {
    font-family: BlenderPro;
    src: url('../../src/assets/fonts/BlenderPro-Book.otf') format('opentype');
  }
    
  @font-face {
    font-family: BlenderPro-Bold;
    src: url('../../src/assets/fonts/BlenderPro-Bold.otf') format('opentype');
  }
  
  @font-face {
    font-family: BlenderPro-Medium;
    src: url('../../src/assets/fonts/BlenderPro-Medium.otf') format('opentype');
  }
    
  @font-face {
    font-family: BlenderPro-Thin;
    src: url('../../src/assets/fonts/BlenderPro-Thin.otf') format('opentype');
  }
    

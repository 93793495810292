//TAILWIND_REFACTORED
 .advanced-search {
  .container {
    @apply h-3/4 #{!important};
  }
  .sub-header {
    @apply text-grey/52 text-[0.8125rem] mb-3;
  }
  .search-section {
    @apply grid gap-y-2 mb-8;
    .search-info {
      @apply grid ;
      grid-template-columns: 13.75rem 20rem 2rem 2rem;
      grid-template-rows: 2rem;
      .icon {
        @apply text-grey/52 cursor-pointer pt-2 pr-0 pb-0 pl-2;
      }
    }
    .lookup {
      @apply my-2 mx-0;
    }
    .hashing-type {
      @apply ml-5 grid gap-y-1;
      grid-template-columns: 14.5rem;
      grid-template-rows: 0.8125rem 2rem;
      ng-select {
        .ng-select-container {
          @apply rounded h-8;
        }
      }
    }
  }
  ng-select {
    @apply inline-block;
    .ng-select-container {
      @apply rounded-l rounded-r-none h-[2.625rem] bg-grey-50 #{!important};
    }
  }
  .selectAttribute {
    @apply text-grey-900 #{!important};
  }
  .search-input {
    .input-comp {
      @apply h-8 w-full;
    }
    .main-container {
      @apply bg-grey-50 rounded-l-none rounded-r #{!important};
    }
    .msgs-container {
      @apply h-0;
    }
  }
}

 .tooltip-text-1 {
  @apply w-max p-2 #{!important};
}
 .advanced-search-dropdown.ng-dropdown-panel {
  &.ng-select-bottom {
    @apply mt-2.5 #{!important};
  }
}

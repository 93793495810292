@import "./variables";
@import "./functions";

.icon {
  margin-right: 5px;

  &:before {
    display: inline-block;
    font-family: "unity-icons" !important;
  }

  &.cancel:before {
    content: $cancel;
  }

  &.delete:before {
    content: $delete;
  }

  &.unknown:before {
    content: $unknown;
  }

  &.copy:before {
    content: $copy;
  }

  &.edit:before {
    content: $edit;
  }

  &.activate:before {
    content: $activate;
  }

  &.filled-square:before,
  &.unchecked:before {
    content: $unchecked;
  }

  &.tick:before,
  &.checked:before {
    content: $checked;
  }

  &.square:before {
    content: $square;
  }

  &.dotted-circles:before {
    content: $dotted-circles;
  }

  &.search:before {
    content: $search;
  }

  &.up-arrow:before {
    content: $up-arrow;
  }

  &.angle:before {
    content: $angle;
  }

  &.download:before {
    content: $download;
  }

  &.success:before {
    content: $success;
  }

  &.down-triangle:before {
    content: $down-triangle;
  }

  &.tableIcon:before {
    content: $table;
  }

  &.lockIcon:before {
    content: $lock;
  }

  &.filter:before {
    content: $filter;
  }

  &.next:before,
  &.pull-right:before {
    content: $next;
  }

  &.help:before {
    content: $help;
  }

  &.settings:before {
    content: $settings;
  }

  &.users:before {
    content: $users;
  }

  &.partner:before {
    content: $partner;
  }

  &.insights:before {
    content: $insights;
  }

  &.segments:before {
    content: $segments;
  }

  &.calendar:before {
    content: $calendar;
  }

  &.dollar:before {
    content: $dollar;
  }

  &.impression:before {
    content: $impression;
  }

  &.apps:before {
    content: $apps;
  }

  &.details:before {
    content: $details;
  }

  &.interests:before {
    content: $interests;
  }

  &.automotive:before {
    content: $automotive;
  }

  &.device:before {
    content: $device;
  }

  &.demographics:before {
    content: $demographics;
  }

  &.close:before,
  &.cross:before {
    content: $cross;
  }

  &.add:before {
    content: $add;
  }

  &.male:before {
    content: $male;
  }

  &.female:before {
    content: $female;
  }

  &.reload:before {
    content: $reload;
  }

  &.informationIcon:before {
    content: $information;
  }

  &.not-selected:before,
  &.minimize:before {
    content: $minimize;
  }

  &.error:before,
  &.ingestion_paused::before {
    content: $error;
  }

  &.selected:before {
    content: $selected;
  }

  &.mapp:before {
    content: $mapp;
  }

  &.mweb:before {
    content: $mweb;
  }

  &.pixel::before {
    content: $pixel;
  }

  &.studio::before {
    content: $studio;
  }

  &.ingestion_config::before {
    content: $ingestion;
  }

  &.sampling::before {
    content: $sampling;
  }

  &.contract::before {
    content: $contract;
  }

  &.regular_ingestion::before {
    content: $ingestion-regular;
  }

  &.globe::before {
    content: $globe;
  }

  &.id::before {
    content: $id;
  }

  &.man::before {
    content: $man;
  }

  &.csv::before {
    content: $csv;
  }

  &.taxonomy::before {
    content: $taxonomy;
  }

  &.bucket::before {
    content: $bucket;
  }

  &.merge::before {
    content: $merge;
  }

  &.add_policy::before {
    content: $add-policy;
  }

  &.blacklist::before {
    content: $blacklist;
  }

  &.tool-tip::before {
    content: $tooltip;
  }

  &.export::before {
    content: $export;
  }

  &.circular-tick::before {
    content: $circular-tick;
  }

  &.watch::before {
    content: $watch;
  }

  &.psychographic::before {
    content: $psychographic;
  }

  &.remove::before {
    content: $remove;
  }

  &.discover:before {
    content: $discover;
  }

  &.distribute:before {
    content: $distribute;
  }

  &.crayon:before {
    content: $crayon;
  }

  &.connect:before {
    content: $connect;
  }

  &.upload:before {
    content: $upload;
  }

  &.match-rate:before {
    content: $match-rate;
  }

  &.destination:before {
    content: $destination;
  }

  &.connect-insight:before,
  &.statistics:before {
    content: $statistics;
  }

  &.distribute-icon:before {
    content: $distribute-v2;
  }

  &.destinations:before {
    content: $destinations;
  }

  &.matchrate:before {
    content: $match;
  }

  &.intent:before {
    content: $intent;
  }

  &.round-stack:before {
    content: $round-stack;
  }

  &.square-stack:before {
    content: $square-stack;
  }

  &.source:before {
    content: $source;
  }

  &.target:before {
    content: $target;
  }

  &.dpui:before {
    content: $dpui;
  }

  &.copy-clipboard:before {
    content: $copy-to-clipboard;
  }

  &.basic-detail:before {
    content: $detail;
  }

  &.add-destination-icon:before,
  &.distribute-segment:before {
    content: $distribute-and-add;
  }

  &.inputs-matched:before {
    content: $inputs-matched;
  }

  &.lookalike-matched:before {
    content: $lookalike-matched;
  }

  &.preview:before {
    content: $preview;
  }

  &.downloadpdf:before {
    content: $download-pdf;
  }

  &.rbac:before {
    content: $rbac;
  }

  &.email:before {
    content: $email;
  }

  &.purchase:before {
    content: $purchase;
  }

  &.dcr:before {
    content: $dcr;
  }

  &.logout:before {
    content: $logout;
  }
  &.data-manager:before {
    content: $data-manager;
  }
  &.website-event:before {
    content: $website-event;
  }
  &.pixel-event:before {
    content: $pixel-event;
  }
  &.js-event:before {
    content: $js-event;
  }
  &.iframe-event:before {
    content: $iframe-event;
  }
  &.app-event:before {
    content: $app-event;
  }

  &.http-event:before {
    content: $http-event;
  }

  &.s2s-event:before {
    content: $s2s-event;
  }

  &.tag-managers:before {
    content: $tag-managers;
  }

  &.travel:before {
    content: $travel;
  }
  &.pause:before {
    content: $pause;
  }
  &.play:before {
    content: $play;
  }
  &.thumb:before {
    content: $thumb;
  }
  &.add-settings:before {
    content: $add-settings;
  }
  &.icon-impression:before {
    content: $eye;
  }
  &.icon-click:before {
    content: $target-click;
  }
  &.icon-conversion:before {
    content: $filter-conversion;
  }
  &.sort:before {
    content: $sort;
  }
  &.affinity-360:before {
    content: $affinity-360;
  }
  &.tools:before {
    content: $tools;
  }
  &.grid-listing:before {
    content: $grid-listing;
  }
  &.tabular-listing:before {
    content: $tabular-listing;
  }
  &.symphony:before {
    content: $symphony;
  }
  &.symphony_v1::before {
    content: $symphony_v1;
  }
  &.restore:before {
    content: $restore;
  }
  &.envision:before {
    content: $envision;
  }
  &.id-plus:before {
    content: $id-plus;
  }
  &.document:before {
    content: $document;
  }
  &.brands:before {
    content: $brands;
  }
  &.calender:before {
    content: $calendar;
  }
  &.column-listing:before {
    content: $column-listing;
  }
  &.notification:before {
    content: $notification;
  }
  &.source-name:before {
    content: $source-name;
  }
  &.chart:before {
    content: $chart;
  }
  &.download-report:before {
    content: $save;
  }
  &.received:before {
    content: $recieved;
  }
  &.in-progress:before {
    content: $in-progress;
  }
  &.ingested:before {
    content: $ingested;
  }
  &.failed:before {
    content: $failed;
  }
  &.file-dropped:before {
    content: $file-drop;
  }
  &.error-count:before {
    content: $error-count;
  }
  &.guide:before {
    content: $guide;
  }
  &.have_certain_attributes:before {
    content: $user;
  }
  &.have_change_in_attribute:before {
    content: $unity-icons-change-in-attr;
  }
  &.id_meta_category:before {
    content: $id-meta;
  }
  &.campaign_category:before {
    content: $campaign;
  }
  &.consent_category:before {
    content: $consent;
  }
  &.event_category:before,
  &.diagonal-arrow:before,
  &.performed_an_event:before {
    content: $event;
  }
  &.product_category:before {
    content: $product;
  }
  &.marketing_preference_category:before {
    content: $mkt-pref;
  }
  &.id_category:before {
    content: $user-id;
  }
  &.calculated_attribute_category:before {
    content: $calculated-attribute;
  }
  &.persons:before,
  &.user_traits_category:before {
    content: $users-v2;
  }
  &.delay:before {
    content: $delay;
  }
  &.split:before {
    content: $split;
  }
  &.condition:before {
    content: $condition;
  }
  &.refresh:before {
    content: $refresh;
  }
  &.filter-info:before {
    content: $unity-icons-filter-icon;
  }
  &.meta-info:before {
    content: $unity-icons-filter;
  }
  &.push_to_destination:before {
    content: $unity-icons-send;
  }
  &.customer-360:before {
    content: $unity-icons-user360;
  }
  &.lifecycle-add:before {
    content: $lifecycle-add;
  }
  &.circular-dot:before {
    content: $unity-icons-dot;
  }
  &.flag:before {
    content: $flag;
  }
  &.scissors:before {
    content: $unity-icons-scissors;
  }
  &.circular-info:before {
    content: $circular-info;
  }
  &.person:before {
    content: $person;
  }
  &.have_entry_event:before {
    content: $unity-icons-have-entry-event-attr;
  }
  &.feed_category:before {
    content: $unity-icons-circle-outline;
  }
}

.add-operand-btn,
.add-ruleset-btn {
  .mat-icon {
    @apply bg-primary-1/10;
  }
}
.main-operand-container .delete-icon,
.wf-query-builder .delete-icon {
  @apply text-state-error-700 bg-state-error-700/10;
}

.wf-query-builder {
  .add-button .mat-icon,
  .add-ruleset-btn .mat-icon {
    @apply text-primary-1;
  }
}

.tag-dropdown .ng-value,
.tag-dropdown .ng-option,
.source-dropdown .ng-value,
.source-dropdown .ng-option {
  @apply text-grey-800;
}

.workflow-destination-modal {
  .back-label {
    @apply font-primary-medium text-sm;
  }
  zap-destination-selectable-list {
    .poster {
      @apply border-[0.875613rem] border-solid border-grey-300/50 #{!important};
    }
  }
  .details-container {
    .stepper {
      @apply border-0 border-b border-solid border-b-grey-300/30 #{!important};
      .stepHeader {
        @apply text-sm text-[#979797] #{!important}; //TODO: Replace hardcoded color
      }
      .activeStep {
        @apply border-0 border-b-[3px] border-solid border-b-primary-1 #{!important};
      }
      .activeStepText {
        @apply text-primary-1 #{!important};
      }
    }
  }
}

.wf-status {
  &.failed::before {
    @apply bg-state-error-500/90;
  }
}

.wf-status {
  &.active::before {
    @apply bg-state-success;
  }
}

.wf-status {
  &.draft::before {
    @apply bg-state-warning-1;
  }
}

.wf-status {
  &.published::before {
    @apply bg-primary-1;
  }
}

.wf-status {
  &.paused,
  &.archived {
    &::before {
      @apply bg-grey-400;
    }
  }
}

.wf-status {
  &.inprogress::before {
    @apply bg-state-warning-1-300;
  }
}

.third-party-lookup-droplist .ng-select-container .ng-value-container .ng-placeholder {
  @apply text-grey/52 top-3 font-primary #{!important};
}


zap-custom-query-builder{
    .error-border {
        @apply border border-state-error-700 border-solid #{!important};
    }

   .remove-holder {
        @apply text-state-error-700 cursor-pointer text-[1.225rem] rounded h-[1.375rem] w-[1.375rem] flex items-center justify-center;
    }
    .remove-holder:hover {
        @apply bg-state-error-700/10;
    }
    .query-row {
        display: grid;
        align-items: center;
        gap: 1rem;
        grid-template-columns: minmax(7.5rem, 1fr) minmax(6.25rem, 0.5fr) minmax(7.5rem, 1fr) 2rem;
        height: 2rem;
    }
    .query-container {
        margin-left: 2rem;
        position: relative;
        &::before {
            top: -1rem;
            border-width: 0 0 2px 2px;
        }
        &::after {
            top: .625rem;
            border-width: 0 0 0 2px;
        }
        &::before, &:not(:last-child)::after {
            content: '';
            left: -0.9375rem;
            border-style: solid;
            width: .9375rem;
            height: 100%;
            position: absolute;
        }
    }

    .condition {
        width: 5rem;
    }
    .queries-grp-container, .queries-container {
        display: grid;
        align-items: center;
        gap: 1rem;
    }
    .queries-grp-container {
        @apply border border-solid border-grey-300 bg-none rounded p-4;
    }
    .query-input {
        height: 2rem;
    }
    ::placeholder {
        @apply text-grey/52;
    }
    .query-textBox {
        @apply border border-solid border-grey-300 rounded w-full h-8 p-[0.3125rem];    
    }
    .no-queries {
        @apply text-grey/52 flex justify-center items-center;
    }
    .disabledBtn {
        @apply bg-grey-100 text-grey/52  pointer-events-none;
    }

    .warning-msg {
        @apply text-state-error-700 text-xs px-0 py-4;
    }

    .and-border {
        &::before,
        &::after {
            @apply border-state-warning-1;
        }
    }
    .or-border {
        &::before,
        &::after {
            @apply border-state-success;
        }
    }
    .and-bg {
        @apply bg-state-warning-1/5;
    }
    .or-bg {
        @apply bg-state-success/5;
    }
    .add-icon-container {
        display: flex;
        align-items: center;
        float: right;
    }

    :host { 
        .builder-dropdown {
            @apply text-primary-1 text-sm;
            .ng-dropdown-panel-items {
                max-height: 12.5rem !important;
                min-width: max-content;
            }
        }
        .ng-select {
            @apply rounded h-8;
        }
        .ng-select-container {
            height: 2rem !important;
            min-height: 2rem !important;
            .ng-value-container .ng-input>input {
                padding-top: 0.25rem;
            }
            .ng-clear-wrapper {
                display: none !important;
              }
        }
        .error-dropdown-border .ng-select-container {
            @apply border border-state-error-700 border-solid #{!important};
        }
        .add-btn {
            margin: 0 0.5rem;
            mat-icon {
                font-size: 1.125rem;
            }
        }
        .add-btn, .add-btn mat-icon {
            height: 1.125rem;
            width: 1.125rem;
            line-height: 1.125rem;
        }
        .toggle-window-container .option {
            padding: .25rem 0.65rem !important;
        }
    }
    
}

.rule-tooltip {
    width: max-content !important;
    padding: 0.3125rem 0.5rem !important;
    .simpleText {
        font-size: 0.75rem;
        display: inline-block;
    }
}
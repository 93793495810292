// TODO : add possible css back in html

 .range {
    @apply text-sm #{!important};
    .mat-form-field-flex {
        @apply border border-grey-300 rounded border-solid bg-white #{!important};
    }
    .mat-input-element:disabled {
        @apply text-primary-1-900;
    }
    &.disabled .mat-form-field-flex {
        @apply bg-grey-100;
    }
}

.range-picker .mat-calendar-body-selected {
    @apply bg-primary-1;
}
.event-date-range-error {
    @apply min-w-[14rem] max-w-[18rem];
    @apply w-max leading-4 px-2.5 py-[0.3125rem] #{!important};
}
.event-date-range-error .error {
    @apply text-state-error-700;
}
.event-date-range-error .error-text {
    @apply text-xs text-white;
}
.error-datepicker-border .mat-form-field-flex {
    @apply border border-solid border-state-error-700 #{!important};
}

.card-loader {
    @apply h-full;
    align-self: normal;
}
.card-loader .inProgressText .text {
    @apply text-white #{!important};
}
.card-loader .dots span {
    @apply bg-white #{!important};
}

.range {
    .mat-form-field-wrapper {
        padding-bottom: 0;
    }
    .mat-form-field-underline {
        display: none;
    }
    .mat-form-field-infix {
        border-top: none;
    }
    input.mat-input-element {
        text-align: center;
    }
}
.add-catalog-legacy {
.add-catalog-label {
  @apply text-base;
}
.wrap-loader {
  left: 0;
  pointer-events: none;
  z-index: 10000 !important;
}
zap-modal-holder {
  .sideOverlay-container {
    width: 55% !important;
  }
  .body-section {
    background-color: white !important;
    padding-bottom: 0;
  }
}
.standard-input {
  &::placeholder,
  &::-webkit-input-placeholder {
    font-style: normal !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.add-catalog-clipboard.clipboard:hover {
  .copyBtn {
    cursor: pointer;
    @apply border border-solid border-grey-300/30;
  }
  .tooltiptext {
    margin-left: 1.5rem !important;
  }
}
.add-catalog-clipboard.clipboard {
  margin-left: 0.875rem;
}

.source-field-info {
  display: flex;
  margin-top: 1.0625rem;
  align-items: center;
  min-width: 10rem;
  max-width: 21rem;
  line-height: 0.8125rem;
  white-space: pre-wrap;
  @apply text-grey/52 text-2xs;
}
.add-catalog-header {
  align-self: center;
}
.edit-header {
  display: flex;
  gap: 2rem;
  line-height: 1rem;
  @apply text-grey/52 text-[0.8125rem];
  div {
    padding: 0.5rem 2rem 0.5rem 2rem;
    cursor: pointer;
  }
  .active-header {
    @apply border-b border-solid border-primary-1 text-primary-1;
  }
}
.category-error {
  display: flex;
  margin-top: 0.5rem;
  @apply text-xs;
}
.field-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.mapping-fields {
  display: flex;
  row-gap: 0.6456rem;
  width: 95%;
  flex-direction: column;
  .mapping-fields-info {
    display: flex;
    align-items: flex-start;
    gap: 0.5831rem;
    grid-column: 1/3;
    @apply text-grey/52 text-xs;
    .mapping-fields-info-text {
      line-height: 1.02rem;
    }
  }
  .catalog-screen {
    display: flex;
    width: 100%;
    .field-container:nth-child(1) {
      width: 45%;
    }
    .field-container:nth-child(2) {
      width: 55%;
    }
  }
  .map-catalog-to-source {
    flex-direction: row-reverse;
    .field-container:nth-child(1) {
      width: 55%;
    }
    .field-container:nth-child(2) {
      width: 45%;
    }
    .catalog-field {
      .ng-select-container {
        border-radius: 0 0.25rem 0.25rem 0;
      }
    }

    .unity-icons-arrow::before {
      position: absolute;
      transform: rotate(90deg);
      z-index: 1002;
      background: white;
      top: 3rem;
      left: -0.5rem;
    }
    .source-field {
      width: 100% !important;
      padding: 1.375rem 1rem 1.375rem 1rem;
      height: 4rem !important;
      border-radius: 0.25rem 0 0 0.25rem;
      border-right: 0;
      background: white;
      @apply text-base font-primary-medium;
      &.disabled {
        @apply bg-grey-100;
      }
    }
  }
}
.adv-settings {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-left: 1.5rem;
  margin-top: 1.5rem;
  padding-bottom: 1.25rem;
}
.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  flex-direction: row;
  align-items: center;
  input[type="radio"] {
    position: fixed;
    opacity: 0;
    pointer-events: none;
  }
}
.raw-pii {
  gap: 1.0625rem;
}

.category {
  cursor: pointer;
  display: flex;
  width: max-content;
  gap: 0.1875rem;
  padding: 0.4375rem 0.6875rem;
  align-items: center;
  @apply text-[0.8125rem] bg-grey-50 border border-solid border-grey-300/30 rounded text-grey/52;
  &.category-active {
    @apply text-primary-1 border border-solid border-primary-1;
  }
  &.category-disabled {
    cursor: default;
    pointer-events: none;
    @apply text-grey/52 border border-solid border-grey/52 bg-grey-100;
  }
}

.add-catalog-form-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  height: 100%;
  overflow: scroll;
  @apply rounded;
  .input-label,
  .adv-setting-label {
    @apply text-[0.8125rem] text-grey/52;
  }
  .description-label {
    cursor: pointer;
    @apply text-sm;
    &:hover {
      @apply text-primary-1;
    }
    &.disabled {
      pointer-events: none;
      background: transparent;
      @apply text-grey/36;
    }
  }
  .input-label {
    margin-bottom: 0.5rem;
    width: max-content;
  }
  .catalog-label {
    @apply text-xs;
    margin-bottom: 0;
  }
  .droplist {
    width: 28rem;
    .ng-select-container {
      height: 2.5rem !important;
      width: inherit;
      @apply bg-grey-50 border border-solid border-grey-300;
    }
    .ng-dropdown-panel {
      min-width: max-content;
      width: inherit;
    }
    .ng-placeholder {
      top: unset !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .ng-input {
      top: unset !important;
    }
    .ng-dropdown-panel-items {
      max-height: 12.5rem;
    }
    .ng-dropdown-header {
      height: 1.37rem;
    }
    .ng-tag-label {
      margin: 0.8125rem 1.4375rem 0 0;
      @apply text-sm text-primary-1;
    }
    .option-holder {
      display: flex;
      align-items: center;
      input[type="checkbox"] {
        margin: 0 0.625rem 0 0 !important;
      }
    }
    .ng-value {
      white-space: pre !important;
    }
    .custom-ng-value {
      display: flex;
      align-items: center;
      background: white !important;
      border-radius: 0.25rem !important;
      height: 1.625rem;
      @apply shadow-13 text-sm #{!important};
      .ng-value-label {
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .custom-ng-icon {
        padding: 0.25rem !important;
        border-radius: 50%;
        opacity: 1;
        @apply text-2xs text-grey/52 border border-solid border-grey-300/30 bg-grey-50 #{!important};
      }
    }
    .custom-ng-more {
      background: none !important;
      margin-right: 0 !important;
      @apply text-sm text-primary-1 #{!important};
    }
  }
  .ttl,
  .idCount,
  .validation {
    .droplist {
      @apply bg-grey-50;
    }
  }
  .grp-name,
  .validation .droplist,
  .regulation .droplist {
    width: 11.9375rem;
  }
  .data-type {
    width: 8.75rem;
  }
  .ttl .droplist,
  .idCount .droplist {
    width: 7.3125rem;
  }
  .ttl {
    gap: 0.25rem;
    &-value {
      width: 4.375rem;
    }
  }
  .catalog-field {
    width: 100% !important;
    .ng-select-container {
      height: 4rem !important;
      padding: 1.375rem 1rem 1.375rem 1rem;
      border-radius: 0.25rem 0 0 0.25rem;
      background: white;
    }
    .ng-value-container {
      @apply text-base font-primary-medium;
    }
    .ng-input {
      padding-left: 1.625rem !important;
    }
    .ng-arrow-wrapper {
      display: none;
    }
    .ng-placeholder {
      @apply text-base font-primary-medium;
    }
    .ng-dropdown-panel {
      padding: 0.3125rem 0;
    }
    .ng-option {
      padding-left: 1.625rem;
    }
  }
  .source-field {
    width: 100% !important;
    .ng-select-container {
      padding: 1.375rem 1rem 1.375rem 1rem;
      height: 4rem !important;
      border-radius: 0 0.25rem 0.25rem 0;
      border-left: 0;
      background: white;
    }
    .ng-arrow-wrapper {
      display: none;
    }
    .ng-placeholder {
      @apply text-base font-primary-medium;
    }
    .ng-dropdown-panel-items {
      padding: 0.3125rem 0;
    }
    .ng-option {
      padding-left: 1.4375rem;
    }
  }
  .unity-icons-arrow::before {
    position: absolute;
    transform: rotate(-90deg);
    z-index: 1002;
    background: #fff;
    top: 3rem;
    left: -0.5rem;
  }
  .disabled {
    pointer-events: none;
    .ng-arrow-wrapper {
      display: none !important;
    }
    .ng-select-container {
      @apply bg-grey-100;
    }
    @apply bg-grey-100;
  }

  textarea:disabled {
    @apply bg-grey-100;
  }
  .no-pointer {
    cursor: default;
  }
}
.field-type {
  font-style: italic;
  margin: 0 0.25rem;
  align-self: center;
  @apply text-sm text-grey/52;
}
.required-field {
  margin-right: 0.75rem;
  @apply text-state-error-700;
}
.description {
  height: 4rem;
  width: calc(100% - 2rem);
  padding: 0.875rem;
  resize: none;
  overflow-y: scroll;
  line-height: 1.0625rem;
  @apply rounded border border-solid border-grey-300;
  &:focus-visible {
    outline: none;
    @apply border border-solid border-primary-1;
  }
  &::placeholder,
  &::-webkit-input-placeholder {
    @apply text-grey/52;
  }
}
.data-field {
  @apply inline-flex text-grey-800 text-[0.8125rem];
}
.catalog-info {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  cursor: pointer;
  padding: 0.2rem 0.3125rem;
  @apply border border-solid border-grey-300/30 text-grey/52 bg-grey-100 text-3xs;
}
.component-loader {
  height: 100%;
}
.error-dropdown-border .ng-select-container {
  @apply border border-solid border-state-error-700 #{!important};
}
}

.catalog-input-tooltip {
  min-width: 10.5rem;
  max-width: 18rem;
  padding: 0.3125rem 0.5rem !important;
  .simpleText {
    @apply text-sm inline-block;
  }
}
.category-tooltip {
  width: min-content !important;
  min-width: 10.5rem;
  max-width: 18rem;
  padding: 0.3125rem 0.5rem !important;
  .simpleText {
    @apply text-sm inline-block;
  }
}

.catalog-input-validation {
  min-width: 14rem;
  max-width: 18rem;
  width: max-content !important;
  line-height: 1rem !important;
  padding: 0.3125rem 0.625rem !important;
  .error {
    @apply text-state-error-700;
  }
  .error-text {
    @apply text-white text-sm;
  }
}

.catalog-input-info {
  width: max-content !important;
  line-height: 1rem !important;
  padding: 0.3125rem 0.625rem !important;
}

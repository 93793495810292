:host {
  @media screen and (min-width: 1300px) {
    .page-top-section {
      grid-template-columns: 16.625rem auto 17.625rem 3.75rem !important;
      grid-template-rows: 4rem auto !important;
    }
    .name-input-holder,
    .errorSpan {
      grid-row: 1 !important;
      grid-column: 2/3 !important;
    }
  }
}

zap-data-group-create{
  .page-holder {
    @apply bg-grey-150 fixed w-full h-full z-[99] pt-[0.3125rem] pb-16 left-0 top-0;
  }
  .table-component-holder {
    padding-left: 1rem;
    padding-right: 1rem;
    height: calc(100% - 4rem);
  }
  .mapper-container {
    height: 100%;
    overflow-y: scroll;
  }
  .table-component-holder::-webkit-scrollbar {
    display: none;
  }
  .preview-table-container {
    height: 100%;
  }
  /* Hide scrollbar for IE and Edge */
  .table-component-holder {
    -ms-overflow-style: none;
  }

  .page-top-section {
    display: inline-grid;
    grid-template-columns: auto 17.625rem 3.75rem;
    grid-template-rows: 2.5rem auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;

    .page-title {
      grid-column: 1/2;
      margin-top: 0.3125rem;
      color: #677490;
    }

    .name-input-holder {
      display: flex;
    }

    .name-input-holder,
    .errorSpan {
      grid-row: 2;
      grid-column: 1/3;
    }
  }

  .org-selector {
    .main-container {
      width: 17.187rem;
    }
  }
  .page-top-section {
    .input-container {
      height: 2.5rem;
    }
    .msgs-container {
      height: 0 !important;
    }
  }
  .fixed-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 0 0.5rem 0;
  }
  .cancel-button-holder {
    margin-left: 5rem;
  }
  .go-button-holder {
    margin-right: 5rem;
  }
  .cancel-button {
    text-transform: capitalize;
  }
  .cancel-button .button-comp {
    @apply text-primary-1 capitalize bg-white border shadow-none border-solid border-primary-1;
  }
  .done-button-disabled {
    .button-comp {
      @apply bg-grey-100 text-grey/52;
    }
  }
  .done-button {
    .button-comp {
      text-transform: capitalize;
    }
  }
  .error-box-holder {
    @apply bg-state-error-700/10 text-state-error-700 h-11 flex items-center mb-[1.125rem] pl-[1.56rem];
  }
  .loader-holder {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .dialog-box {
    width: 548px !important;
    .bottom-btn {
      .error-text {
       @apply text-base font-secondary-medium;
      }
      .error-text:hover {
        @apply bg-state-warning-1 rounded px-[1.2rem] py-2;
      }
      .cancelbtn {
        @apply text-base font-secondary-medium;
      }
      @apply text-base #{!important};
    }
  }
  .faded {
    opacity: 0.2;
    pointer-events: none;
  }

  .errorSpan {
    padding: 0.875rem 0 0 0.7rem;
    display: flex;
  }
  .action-box-holder.analytic-help {
    @apply rounded border mb-2 border-solid border-grey-300;
  }
  .add-catalog-field .overlay {
    @apply z-[100];
    .body-section {
      // height: calc(100% - 4rem) !important;
    }
    .footer {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
  zap-action-message-box.help-content {
    .info-bg {
      @apply bg-white h-max grid-cols-[auto] p-2;
    }
    .info-container {
      @apply h-max #{!important};
    }
    .chip-content {
      @apply bg-grey-50 w-fit border border-grey-300/30 inline-flex mr-1 mb-1 px-2 py-1 rounded-[1.125rem] border-solid;
   
      .img-content {
        padding-left: 0.2rem;
      }
      .chip-header {
        @apply text-grey/52 px-[0.2rem] py-0 #{!important};
      }
    }
    .catalogue-field {
      @apply text-grey/52 font-bold text-sm;
    }
    .messageIcon {
      height: max-content !important;
    }
  }
  zap-action-message-box.success-msg {
    .info-bg {
      @apply bg-state-success/10 border border-state-success h-10 px-2 py-0 border-solid #{!important};
      .zui-content {
        @apply text-state-success #{!important};
      }
    }
    .messageIcon {
      @apply text-white bg-state-success text-[0.7rem] h-[1.1rem] w-[1.1rem] font-bold #{!important};
    }
    .icon.selected::before {
      position: relative !important;
      left: -1px !important;
    }
  }
  zap-action-message-box.error-msg {
    .messageIcon {
      @apply text-state-error #{!important};
    }
  }
  .consent-mapper {
    @apply bg-white shadow-13 rounded w-full;
  }
  .missing-enricher-tooltip {
    min-width: 11.125rem;
    width: 11.125rem !important;
    padding: 0.5625rem !important;
    line-height: 0.875rem !important;
    .simpleText {
      @apply text-xs #{!important}
    }
  }
  .mapping-preview-modal-container .overlay {
    @apply z-[99] #{!important};
    .body-section {
      @apply h-[calc(100%_-_4rem)] p-0 #{!important};
    }
  }
  
}

.field-name-tooltip {
  width: max-content !important;
  padding: 0.3125rem 0.5rem !important;
  .simpleText {
    @apply text-xs inline-block;
  }
}
.data-type-mismatch-tooltip {
  width: 16rem !important;
  padding: 0.75rem 1rem 0.75rem 0.8125rem !important;
  .data-type-mismatch-container {
    @apply text-grey-50 #{!important};
    .info {
      display: block;
      margin-top: 0.25rem;
    }
    .header {
     @apply block mb-1 text-sm #{!important}
    }
    
  }
  .header,
  .source,
  .target {
    @apply text-white #{!important};
  }
  .simpleText {
    @apply text-sm  inline-block;
  }
}
zap-identities {
  .identities-container {
    @apply h-[calc(100%_-_5rem)] overflow-y-scroll p-4 bg-white;
}
.identities-container .content-container {
    @apply h-[calc(100%_-_7.875rem)] mt-8;
}
.identities-container .content-container .subContent-container {
    @apply h-max px-8 py-4;
}
.identities-container .content-container .subContent-container .id-config-info-box {
    @apply mb-[1.6125rem];
}
.identities-container .setHeight {
    @apply h-[calc(100%_-_5.5rem)];
}
.identities-container .default-box-header {
    @apply flex flex-row;
}
.identities-container .type {
    @apply border border-solid border-grey-300/30 rounded h-6 ml-9 px-[0.5625rem] py-[0.1875rem] bg-grey-100;
}
.identities-container .title {
    @apply text-primary-1-900 pt-[0.3125rem];
}
.identities-container .textContent {
    @apply text-grey-800 leading-5 pt-[0.3125rem];
}
.step {
    @apply mb-[0.5625rem];
}
.step-index {
    @apply text-grey/52;
}
.step-title {
    @apply text-primary-1-900 ml-5;
}
.step-subtext {
    @apply text-grey/52 text-xs mb-3;
}
.component-loader {
    @apply h-full;
}
.preview-header-text {
    @apply text-grey-800 flex items-center p-3;
}
.default-preview-holder {
    @apply border-t-grey-300/30 border-t border-solid;
}
.custom-preview-holder {
    @apply border-t-grey-300/30 border-t border-solid;
}
.idType {
    @apply border border-solid border-grey-300/30 min-w-[0.8125rem] min-h-[0.8125rem] max-w-[0.8125rem] max-h-[0.8125rem] text-center text-grey-800 text-xs mx-[0.9375rem] my-0 rounded-[50%] bg-grey-100;
}
.info-box {
    @apply text-grey/52 text-xs h-12 flex items-center;
}
.info-icon {
    @apply h-4 w-4 text-2xs text-center border border-solid border-grey-300/30 ml-8 pt-0.5 rounded-[50%] bg-grey-100;
}
.sub-heading {
    @apply text-grey/52 mt-[1.875rem] mb-2 mx-0;
}
.error-border {
    @apply border border-state-error-700 border-solid;
}
.continue-btn {
    @apply h-10 text-white rounded w-max cursor-pointer flex items-center mx-4 my-0 px-3 py-[0.63125rem] bg-primary-1;
}
.continue-btn .up-arrow::before {
    @apply rotate-90 ml-2.5;
}
.bottom-bar {
    @apply h-[3.625rem] border-t border-solid border-grey/52 sticky w-full flex items-center justify-between left-0 bottom-5;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)); // TODO: update
}
.edit-link {
    @apply text-primary-1 text-sm pl-2 pt-2;
}
.header-text {
    @apply font-normal text-base text-grey/52 uppercase;
}
.primaryInfo .info-container {
    @apply h-8 bg-white mb-2 #{!important};
}
.primaryInfo .info-container .info-content div .zui-content {
    @apply text-grey/52 text-xs #{!important};
}
.primaryInfo .messageIcon {
    @apply text-[0.65rem] h-[1.275rem] w-[1.275rem] ml-2 mr-4 p-[0.275rem] #{!important};
}
zap-template-table .table-container {
    @apply h-[calc(100%_-_2rem)] #{!important};
}
zap-template-table .table-column {
    @apply flex items-center #{!important};
}
.tooltip-custom {
    @apply min-w-[14rem] max-w-[15.5rem] w-max leading-6 px-2.5 py-0 #{!important};
}
.tooltip-custom .error {
    @apply text-state-error-700 #{!important};
}
.tooltip-custom .error-text {
    @apply text-xs text-white #{!important};
}
.tooltip-idType {
    @apply min-w-[7.8125rem] w-max p-[0.3125rem] #{!important};
}
.tooltip-idType .simpleText {
    @apply text-xs #{!important};
}
.category-hearder {
    @apply flex justify-center #{!important};
}
.subtext {
    @apply text-grey-800 leading-5 pb-[0.3125rem] #{!important};
}
.priority-v2-modal .body-section {
    @apply overflow-y-hidden bg-white #{!important};
}
.priority-v2-modal .footer {
    @apply shadow-13 mt-[0.035rem] #{!important};
}
.priority-v2-modal .sideOverlay-container {
    @apply w-[50rem] #{!important};
}
.priority-v2-modal .header-box {
    @apply grid-cols-[1fr_1.875rem] #{!important};
}
.priority-v2-modal .header-box .header-label {
    @apply flex items-center gap-4 #{!important};
}
.priority-v2-modal .header-box .header-label .icon {
    @apply flex items-center gap-1 leading-[1.15rem] font-primary-medium #{!important};
}
.priority-v2-modal .header-box .header-label .icon:before {
    transform: rotateY(180deg) #{!important};
}
.priority-v2-modal .header-box .header-label .disable {
    background: none !important;
}
.doc-help-text .help-center {
    @apply m-0 #{!important};
}
.priority-modal .no-scroll-bar {
    @apply overflow-hidden #{!important};
}
.priority-modal .sideOverlay-container {
    @apply w-full #{!important};
}
.preview-data zap-template-table .table-column:first-of-type {
    @apply text-grey/52 #{!important};
}
.priority-table-v2-container {
    @apply h-[calc(100%_-_3rem)] #{!important};
}
.priority-table-v2 .even-row {
    @apply bg-white #{!important};
}
.priority-table-v2 .table-body {
    @apply h-[calc(100%_-_2.5rem)] overflow-y-scroll #{!important};
}

.modal-doc {
    margin-left: auto;
}

@media only screen and (max-width: 800px) {
    .priority-v2-modal .sideOverlay-container {
        @apply w-full #{!important};
    }
}
}

.side-overlay-preset-1 {
  .sideOverlay-container {
    @apply grid grid-rows-[auto_1fr_4rem] grid-cols-[100%];

    .header-div {
      @apply h-max shadow-none #{!important};
    }

    .header-label {
      @apply grid gap-y-2 pl-[2.3125rem] pr-6 pt-[1.4375rem] pb-4 grid-cols-[0.9375rem_auto] gap-x-[0.9063rem] normal-case;
    }

    .body-section {
      @apply h-full bg-white pl-12 pr-8 pt-0 pb-6;
    }

    .footer {
      @apply shadow-none #{!important};
      .cancel-button,
      .next-button {
        @apply h-10 w-max min-w-0 min-h-0 font-primary-medium;
      }

      .cancel-button {
        @apply bg-white border-[0.0625rem] text-grey-800;
      }

      .next-button {
        @apply bg-primary-1 text-white border-none;
      }
    }
  }

  &.no-overflow .body-section {
    @apply overflow-hidden;
  }
}

.zui-checkbox-preset-1 {
  .checkbox-comp {
    @apply text-[.8125rem];

    input:before,
    .label-text {
      @apply text-[.8125rem];
    }

    .label-text {
      @apply text-grey/52 ml-4;
    }

    @apply p-0 #{!important};
  }
}

.mat-slide-toggle-thumb-container {
  top: -0.125rem !important;
  left: -0.125rem !important;
}

.mat-slide-toggle-thumb {
  width: 1rem !important;
  height: 1rem !important;
  @apply shadow-18;
}

.mat-slide-toggle-bar {
  width: 1.5625rem !important;
  height: 0.75rem !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  @apply bg-primary-1/30 #{!important};
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  @apply bg-primary-1 #{!important};
}

.mat-slide-toggle.mat-checked.mat-disabled .mat-slide-toggle-thumb {
  @apply bg-secondary-3-700 #{!important};
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(0.8125rem, 0, 0) !important;
}

.listing-preset-1.ngx-datatable {
  @apply w-full;
  .datatable-header {
    @apply w-full pl-0;
    .datatable-header-inner {
      @apply w-full #{!important};
      .datatable-row-center {
        @apply w-full #{!important};
        .datatable-header-cell {
          @apply text-[0.6875rem];
          @media screen and (max-width: 1024px) {
            @apply px-2 py-[0.9rem];
          }
        }
      }
    }
  }
  .resize-handle {
    border-right: 0px !important;
  }
  .datatable-row-group {
    @apply border-b-[0.06rem] border-solid border-grey-150 hover:bg-white;
    &:hover {
      transition-duration: 0s !important;
    }
  }
  .datatable-body-cell-label {
    @apply overflow-hidden text-ellipsis mx-0 my-auto text-grey/52;
  }
  .datatable-body {
    @apply w-full #{!important};
    .datatable-scroll {
      @apply w-full #{!important};
      .datatable-row-wrapper {
        @apply w-full pl-0;
        .datatable-body-row {
          @apply cursor-pointer;
          @apply w-full #{!important};
          .datatable-row-center {
            @apply w-full #{!important};
            .datatable-body-cell {
              @apply text-[0.8125rem];
              @media screen and (max-width: 1024px) {
                @apply px-2 py-[0.9rem];
              }
            }
          }
        }
      }
    }
  }
}

.custom-scroll.ngx-datatable .datatable-body {
  -ms-overflow-style: unset; /* IE and Edge */
  scrollbar-width: auto; /* Firefox */

  &::-webkit-scrollbar:horizontal {
    -webkit-appearance: none;
    @apply block h-[.625rem];
  }

  &::-webkit-scrollbar-thumb:horizontal {
    @apply bg-[#3f3e3e]/50  shadow-21 rounded-[.625rem]; //TODO: Fix
  }
  &::-webkit-scrollbar-track {
    @apply bg-grey-50/50 rounded-[.625rem];
  }
}

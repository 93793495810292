zui-button {
  &.btn-right {
    .button-comp {
      @apply float-right;
    }
  }
  &.zui-theme .button-comp {
    @apply cursor-pointer text-white text-base border-0 bg-primary-1 font-secondary-bold;
  }
}
.button-comp {
  @apply rounded-[0.25rem] h-10 px-5 py-0 w-auto min-w-[130px] cursor-pointer text-base border-0;
  outline: none;
  &:hover {
    @apply shadow-11;
  }
  &[disabled] {
    @apply bg-grey-400 text-grey/52 cursor-not-allowed shadow-none;
  }
  &.full-width {
    @apply w-full;
  }

  &.flat {
    @apply rounded-none;
  }

  &.floating {
    @apply h-[38px] w-[38px] min-w-[38px] p-0 rounded-[0.25rem];
    .icon:before {
      @apply pr-0;
    }

    &.big {
      @apply w-[56px] rounded-[56px] h-[56px] min-w-[56px];
    }
  }

  .icon:before {
    @apply bottom-[-0.125rem] relative pr-[0.3125rem];
  }
}

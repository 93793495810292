//TAILWIND_REFACTORED
.profile-listing-container {
  zap-message-prompt {
    .delete-attr-ctn {
      @apply block m-2;
      .attr-name {
        @apply flex-1 text-right;
      }
      .attr-value {
        @apply flex-2 text-start ml-2;
      }
    }
  }
}
  .profile-loader {
    .wrap-loader {
      @apply fixed left-0 #{!important};
    }
  }
zap-profile-listing {
  @apply h-full flex;
}

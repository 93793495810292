// for third party styles
// for ngx-datatable styles
@import "./dropdown.scss";
.ngx-datatable-normal {
    &.ngx-datatable {
        @apply w-full h-full rounded shadow-none;

        .material .resize-handle {
            @apply border-r-0;
        }
        .datatable-body {
            @apply w-full #{!important};
            .datatable-scroll .datatable-row-wrapper {
                @apply w-full pl-0 #{!important};
                .datatable-body-row {
                    @apply w-full #{!important};
                    .datatable-row-center {
                        @apply w-full #{!important};
                        .datatable-body-cell {
                            @apply text-xs #{!important};
                        }
                        .datatable-body-cell-label {
                            @apply mx-0 my-auto;
                            @apply overflow-hidden text-ellipsis text-grey/52 #{!important};
                        }
                    }
                }
            }
        }
        .datatable-row-group {
            @apply border-b-[0.06rem] border-b-grey-150 border-solid;
            @apply hover:bg-white hover:duration-0 #{!important};
        }
        .datatable-header {
            @apply w-full #{!important};
        }
        .datatable-header-inner {
            @apply w-full #{!important};
            .datatable-row-center {
                @apply w-full #{!important};

                .datatable-header-cell {
                    @apply text-xs #{!important};
                    .datatable-header-cell-template-wrap {
                        @apply h-full flex items-center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .ngx-datatable
        .datatable-body
        .datatable-scroll
        .datatable-row-wrapper
        .datatable-body-row
        .datatable-row-center
        .datatable-body-cell {
        @apply px-2 py-[0.9rem] #{!important};
    }
}

// for tooltips

.title-tooltip {
    @apply w-max px-1 py-[0.15rem] #{!important};
    .simpleText {
        @apply text-2xs #{!important};
    }
}

// for icons
.before-rotate-90::before {
    display: inline-block;
    transform: rotate(90deg);
}

.-before-rotate-90::before {
    display: inline-block;
    transform: rotate(-90deg);
}

.before-rotate-180::before {
    display: inline-block;
    transform: rotate(180deg);
}

.required-field-asterisk {
  @apply text-state-error-700;
  margin-right: 0.75rem;
}

.actionText {
  padding-right: 0.94rem;
  float: right;
  font-size: 0.81rem;
}
.error-border {
  @apply rounded border border-solid border-state-error-700 #{!important};
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    @apply m-0;
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}

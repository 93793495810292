.checkbox-comp {
  cursor: pointer;
  display: inline-block;
  padding: 10px 0px;

  * {
    cursor: pointer;
  }

  .label-text {
    display: inline-block;
    height: 20px;
    vertical-align: middle;
  }

  .zui-checkbox {
    margin-right: 5px;
  }

  .zui-checkbox {
    -moz-appearance: none;
    -webkit-appearance: none;

    &:focus {
      @apply outline-none;
    }

    &:before {
      @apply border border-solid border-grey-300 rounded-sm text-base text-white;
    }

    &[disabled]:before {
      @apply text-grey-150 border-grey-250 cursor-not-allowed;
    }

    &:checked:before {
      @apply text-white bg-primary-1 border-primary-1;
    }

    &[disabled]:checked:before {
      @apply text-grey-150 bg-grey-300 border-grey-300;
    }

    &.not-selected:before {
      @apply text-white bg-primary-1 border-primary-1;
    }

    &[disabled].not-selected:before {
      @apply text-grey-150 bg-grey-300 border-grey-300;
    }
  }

  label>span.top {
    display: block;
    padding: 5px 0px;
  }
}
:root {
    --bg-snippet-container: theme(colors.grey.50);
    --text-metadata: theme(colors.grey.DEFAULT/52%);
    --input-border-active-color: theme(colors.primary-1.500);
    --text-action: theme(colors.primary-1.500);
    --bg-content-container: theme(colors.white);
    --input-bg-disabled: theme(colors.grey.100);
    --text-disabled: theme(colors.grey.1000/36%);
    --actionbt-text-primary: theme(colors.primary-1.500);
    --input-icon-color: theme(colors.grey.1000/52%);
    --text-error: theme(colors.state-error.700);
    --input-border-color: theme(colors.grey.300/30%);
    --input-border-active-color: theme(colors.primary-1.500);
    --input-placeholder-color: theme(colors.grey.1000/52%);
    --text-secondary: theme(colors.grey.800);
    --text-white: theme(colors.white);
    --text-black: theme(colors.black);
    --border-data-container: 1px solid theme(colors.grey.300/30%);
    --border-radius-container: 0.25rem;
    --border-snippet-container: 1px solid theme(colors.grey.300/30%);
    --border-container-active: theme(colors.primary-1.500);
    --actionbt-bg-primary: theme(colors.primary-1.DEFAULT/10%);
    --brancolor-higlight: theme(colors.primary-1.DEFAULT);
    --box-shadow: 0px 0px 10px 2px theme(colors.black/10%), 0px 4px 6px -1px theme(colors.black/10%);
    --container-sans-form: 0px 0px 10px 2px theme(colors.black/10%), 0px 4px 6px -1px theme(colors.black/10%);
    --connect-lookalike-error-bg: theme(colors.state-error.100/40%);
    --connect-lookalike-error-border: 1px solid rgba(245, 62, 62, 0.6);  // to be changed
    --destination-lookalike-note-border: 1px solid rgba(0, 116, 255, 0.1);  // to be changed
    --destination-lookalike-note-bg: theme(colors.primary-1.500/4%);
    --status-connect-faded-background-inactive: theme(colors.grey.200/50%);
    --status-background-image-paused-data-manager: theme(colors.grey.200/30%);
    --bg-container-title: theme(colors.grey.200/30%);
    --segment-identifier-hash-color: theme(colors.grey.300);
    --btn-other-border-color: theme(colors.grey.200/50%);
    --shadow-color-table: 0px 1px 3px rgba(59, 65, 94, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.07); // to be changed
    --idmeta-input-border: 1px solid #D9DFE9;  // to be changed
    --destination-lookalike-note-bg: theme(colors.primary-1.500/4%);
    --destination-lookalike-note-border: 1px solid rgba(0, 116, 255, 0.1);  // to be changed
    --dropdown-border: 1px solid #E9EAEC;   // to be changed
    --overlay-background-color: #01164470; // to be changed
    --brandcolor-primary-bg: theme(colors.primary-1.DEFAULT/10%);
    --btn-text-filter: theme(colors.grey.DEFAULT/52%);
    --bg-main-container: theme(colors.white);
    --text-table-header-active: theme(colors.grey.900);
    --actionbt-bg-secondary: theme(colors.state-error.700/10%);
    --text-primary: theme(colors.primary-1.900);
    --btn-shadow-enabled: 0rem 0.125rem 0.625rem theme(colors.primary-1.DEFAULT/64%);
    --btn-bg-disabled: theme(colors.grey.100);
    --icons-border: .0313rem solid theme(colors.grey.300);
    --input-bg: theme(colors.white);
    --sidebar-active-highlight: theme(colors.primary-1.DEFAULT);
    --dropdown-option-bg: theme(colors.grey.200);
    --topBar-text-header: theme(colors.grey.DEFAULT/68%);
    --status-background-active: theme(colors.state-success.DEFAULT);
    --status-background-inprogress: theme(colors.state-warning-1.DEFAULT);
    --status-connect-background-success: theme(colors.state-success.700); // used #5B9C19 instead of #1B9696
    --status-background-failed: theme(colors.state-error.700);
    --status-background-inactive: theme(colors.state-error.700);
    --status-background-paused: theme(colors.grey.400);
    --destination-info-text: theme(colors.grey.100);
    --destination-icon-border: 1px solid theme(colors.white);
    --icon-dark-bg: theme(colors.grey.900/40%);
    --brandcolor-primary: theme(colors.primary-1.DEFAULT);
    --text-table-header: theme(colors.grey.400);
    --input--option-bg: theme(colors.grey.200);
    --status-connect-background-inactive: theme(colors.grey.DEFAULT/52%); // used grey/52 instead of #6C6A6A
    --icon-background: theme(colors.primary-1.100);
    --actionbt-text-primary: theme(colors.primary-1.DEFAULT);
    --btn-text-disabled: theme(colors.grey.DEFAULT/52%); // used grey/52 instead of #969699
    --tooltip-background: theme(colors.grey.800);
    --brandcolor-tertiary: theme(colors.primary-1.900);
    --iconv2-background-listing: theme(colors.grey.300);
    --idmeta-blue-border: theme(colors.primary-1.DEFAULT); // used primary-1.DEFAULT instead of #93B7F4
    --idmeta-icon-color: theme(colors.primary-1.700);
    --bg-checkbox-container: theme(colors.grey.50/60%);
    --login-title-color: theme(colors.grey.900);
    --btn-text-cancel: theme(colors.primary-1.DEFAULT);
    --qb-connector-color-or: theme(colors.state-success.DEFAULT);
    --qb-connector-color-and: theme(colors.state-warning-1.DEFAULT);
    --snackbar-bg-warning: theme(colors.state-warning-1.DEFAULT);
    --snackbar-bg-success: theme(colors.state-success.DEFAULT);
    --snackbar-bg-error: theme(colors.state-error.DEFAULT);
    --idmeta-invalid-query-border: 1px solid theme(colors.state-error.700);
    --brandcolor-secondary: theme(colors.primary-1.700);
    --btn-other-bg: theme(colors.grey.50);
    --btn-close-bg: theme(colors.grey.150);
    --status-faded-background-active: theme(colors.state-success.DEFAULT/10%);
    --status-faded-background-inprogress: theme(colors.state-warning-1.DEFAULT/10%);
    --status-connect-faded-background-success: theme(colors.state-success.700/10%);
    --status-faded-background-failed: theme(colors.state-error.700/10%);
}

zap-dgmapper{
  .datatable-group-header {
    border: none !important;
    background: none !important;
  }
  ng-dropdown-panel.target-field-options {
    z-index: 999 !important;
  }
  .ngx-datatable .datatable-body .datatable-row-wrapper {
    @apply w-max mx-7;
  }
  .ngx-datatable.material .datatable-header {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .ngx-datatable .datatable-body .datatable-body-row > div {
    align-items: baseline;
  }
  .ngx-datatable.material .datatable-footer {
    border-top: none;
    margin: 0 1.75rem 0.75rem 1.75rem;
  }
  .source-field .field-name {
    flex-wrap: wrap;
  }
  .mapper-container .ngx-datatable {
    border-radius: 0.25rem;
    box-shadow: 0px 0px 1px rgba(67, 90, 111, 0.41),
      0px 2px 4px rgba(67, 90, 111, 0.15) !important;
  }
  .search-text {
    @apply transition-[visibility] duration-0 ease-linear delay-300 h-10 text-sm flex pl-6;
    input {
      @apply h-3/6 w-4/5 text-primary-1-900 border-none outline-none;
    }
  }
  .no-search-holder {
    display: flex;
  }
  .close-search {
    @apply text-[0.812rem] pl-[0.2rem] pr-0 pt-[0.2rem] pb-0;
  }
  .no-search {
    @apply text-grey/52 text-sm cursor-pointer pt-[0.1rem];;
  }
  .no-search:hover + .search-icon {
    @apply text-grey/52 cursor-pointer;
  }
  .search-icon {
    @apply text-[0.812rem] ml-[0.1rem] pl-[0.2rem] pr-0 pt-[0.1rem] pb-0;
  }
  .search-icon:hover {
    @apply text-grey/52 cursor-pointer;
  }
  
  .toggle-button {
    background: rgba(0, 116, 255, 0.1);
    border-radius: 0.25rem;
    height: 1rem;
    width: 1rem;
    border: none;
    outline: none;
  }
  .row-toggle {
    @apply text-primary-1 mx-auto my-0;
  }
  .collapsed-icon.row-toggle::before {
    transform: rotate(-90deg);
  }
  .row-toggle::before {
    @apply text-4xs;
  }
  .group-name {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .row-header-grp-name {
    margin-left: 0.625rem;
  }
  .hardcode-source {
    @apply text-xs pl-5 pr-0 pt-2.5 pb-0;
  }
  .faded-row {
    opacity: 0.2;
    pointer-events: none;
  }
  .side-arrow::before {
    transform: rotate(90deg);
  }
  .side-arrow {
    @apply text-grey-300 font-extrabold;
  }
  .datatable-body-cell-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .left-most-cell {
    padding-left: 1.625rem !important;
    .datatable-body-cell-label {
      padding-top: 0rem;
      padding-left: 0.625rem !important;
    }
  }
  .ng-value-label {
    @apply bg-grey-50 rounded-2xl #{!important};
  }
  .custom-ng-value {
    @apply flex flex-row items-baseline bg-grey-50 border border-grey-300/30 box-border rounded-2xl border-solid #{!important};
    .ng-value-icon {
      @apply text-[0.625rem] text-grey/52 rounded border-grey-300/30 box-border p-1 rounded-[50%] border-[0.5px] border-solid opacity-80 #{!important};
      &:hover {
        opacity: 1;
      }
      ::before {
        text-shadow: none;
      }
    }
  }
  .row-header-grp-name {
    font-weight: bold;
  }
  .enricher-action-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .enricher-button {
    @apply flex justify-center items-center cursor-pointer text-sm; 
  }
  .add-enricher-new-btn {
    @apply text-state-error-700;
  }
  .add-enricher-new-btn:hover {
    @apply bg-state-error-700/10;
  }
  .selected-enricher-btn,
  .custom-field-btn {
    @apply text-primary-1;
  }
  .selected-enricher-btn,
  .add-enricher-new-btn,
  .edit-enricher-btn,
  .custom-field-btn {
    @apply w-max rounded leading-4 flex items-center cursor-pointer p-1.5;
  }
  .selected-enricher-btn:hover,
  .custom-field-btn:hover {
    @apply bg-primary-1/10;
  }
  .edit-enricher-btn {
    @apply text-grey-800;
  }
  .edit-enricher-btn:hover {
    @apply bg-grey-50 border border-solid border-grey-300;
  }
  
  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
  .ngx-datatable.material:not(.cell-selection)
    .datatable-body-row:hover
    .datatable-row-center:hover
    .datatable-row-group:hover {
    background-color: transparent !important;
  }
  .datatable-body .datatable-body-row {
    box-shadow: none !important;
  }

  .datatable-row-center.datatable-row-group, .datatable-header .datatable-row-center {
    @apply grid grid-cols-[0.5fr_0.2fr_0.7fr_0.3fr_0.3fr] #{!important};
  }

  .datatable-row-wrapper:not(:first-child) .datatable-group-header {
    border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  .highlight-row .datatable-row-center {
    @apply bg-grey-50 rounded mb-2 #{!important};
    // border-bottom: 0.5px solid rgba(193, 193, 197, 0.6);
    &:hover {
      @apply bg-grey-50 #{!important};
    }
  }
  .non-highlight-row .datatable-row-center {
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
  }

  .dgmapper-mapping-droplist {
    .actionText {
      @apply text-primary-1;
    }
    .ng-dropdown-header {
      min-height: 1.37rem;
      border-bottom: 0;
    }
    .ng-value {
      @apply z-10 #{!important};
    }
  }
  .catalog-fields .ng-value-label .field-name-container {
    max-width: 16rem;
  }
  .close-icon {
    @apply bg-grey-100 opacity-100 text-[#6c7185] h-3.5 w-3.5 rounded flex justify-center items-center font-bold text-[0.56rem] ml-2 p-px;
  }
  
  .custom-field-label {
    @apply text-grey/52;
  }
  
  .delete-mapping-icon {
    @apply cursor-pointer text-xl text-state-error-700 bg-state-error-700/10 rounded h-5 w-5 flex self-center items-center justify-center;
  }
  
  .error-container {
    @apply text-[0.8125rem] flex gap-[0.3125rem] pt-2 pb-0 px-2;
  }
  .connector .error,
  .error-container {
    @apply text-state-error-700;
  }
}

:host .ng-custom-option {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

ng-dropdown-panel {
  .ng-option-name {
    margin-left: 0.625rem;
  }
  
  .add-catalog {
    @apply text-sm text-primary-1 cursor-pointer ml-[0.1875rem] mr-[1.4375rem] mt-[0.5625rem] mb-0;
  }

  .dgmapper-mapping-droplist {
    .ng-dropdown-header {
      min-height: 1.37rem;
      border-bottom: 0;
    }
  }
}

.add-enricher-tooltip {
  min-width: 13rem;
  width: 13rem !important;
  padding: 0.5625rem !important;
  line-height: 0.875rem !important;
  .simpleText {
    @apply text-xs #{!important};
  }
}
  
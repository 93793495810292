.org-list-table-container {
	.datatable-header-inner {
		@apply h-full;
	}

	.ngx-datatable.material {
		.datatable-body-cell-label {
			@apply overflow-hidden whitespace-nowrap truncate #{!important};
		}

		.header-cell {
			@apply border-b-grey-250 #{!important};
		}
	}

	.show-action.ngx-datatable{
    .datatable-header .datatable-header-inner .datatable-row-center {
			@apply grid-cols-[minmax(5rem,1fr)_minmax(6rem,2fr)_minmax(8rem,2fr)_minmax(6rem,2fr)_minmax(6rem,2fr)_15rem] #{!important};
    }
    .datatable-body .datatable-scroll .datatable-row-wrapper .datatable-body-row .datatable-row-center {
      @apply grid-cols-[minmax(5rem,1fr)_minmax(6rem,2fr)_minmax(8rem,2fr)minmax(6rem,2fr)_minmax(6rem,2fr)__15rem] #{!important};
    }
  }
	.datatable-header{
		@apply w-full #{!important};
		.datatable-header-inner{
			@apply w-full #{!important};
			.datatable-row-center{
				@apply  w-full grid grid-cols-[minmax(5rem,1fr)_minmax(6rem,2fr)_minmax(8rem,2fr)_minmax(6rem,2fr)_minmax(6rem,2fr)];
				.datatable-header-cell{
					@apply w-full grid #{!important};
					.datatable-header-cell-template-wrap {
						@apply flex truncate items-center uppercase text-sm #{!important};
					}
				}
			}
		}
	}
	.ngx-datatable {
		width: 100%;
		.datatable-body {
			@apply w-full #{!important};
			.datatable-scroll {
				@apply w-full #{!important};
				.datatable-row-wrapper {
					@apply w-full #{!important};
					.datatable-body-row {
						@apply w-full #{!important};
						.datatable-row-center {
							@apply w-full grid grid-cols-[minmax(5rem,1fr)_minmax(6rem,2fr)_minmax(8rem,2fr)_minmax(6rem,2fr)_minmax(6rem,2fr)];
							.datatable-body-cell {
								@apply w-full grid #{!important};
								.datatable-body-cell-label {
									@apply grid items-center #{!important};
								}
							}
						}
					}
				}
			}
		}
	}
}